import React from 'react';
import { useSelector } from 'react-redux';
import { applyStyles } from '../../utils/applyStyles';
import styles from './styles/EquityDisplay.module.css';
import { Investor } from '../../types/investorTypes';
import { PAMMState } from '../../redux/reducers/rootReducer';
import { VisibilityExtendedToggle, VisibilityToggle } from '../../types/toggles';
import { spaceSeparatedThousands } from '../../utils/number';
import TrendIcon from '../../icons/TrendIcon';

interface Props {
  equityValue: number;
  styleName?: string;
  mmPath?: boolean;
  isSelected?: boolean;
}

const EquityDisplay: React.FC<Props> = ({ equityValue, styleName, mmPath, isSelected = true }) => {
  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);

  const isComponentHidden =
    investor?.AccountOptions?.InvestorHistoryTab === VisibilityExtendedToggle.Hide ||
    investor?.AccountOptions?.InvestorOpenOrdersTab === VisibilityExtendedToggle.Hide;

  const isHiddenForInvMaster =
    investor?.AccountOptions?.MasterHistoryTab === VisibilityToggle.Hide ||
    investor?.AccountOptions?.MasterOpenOrdersTab === VisibilityToggle.Hide;

  if (isComponentHidden && !mmPath) {
    return null;
  }

  if (isHiddenForInvMaster && mmPath) {
    return null;
  }

  let color = '#2fa061';

  if (equityValue === 0) {
    if (isSelected) {
      color = getComputedStyle(document.documentElement).getPropertyValue('--text-lighter-color').trim();
    } else {
      color = getComputedStyle(document.documentElement).getPropertyValue('--button-empty-color').trim();
    }
  } else if (equityValue < 0) {
    color = '#c36167';
  }

  return (
    <span style={{ color }} className={applyStyles(['text-small-semibold', styles[`${styleName}`] || ''])}>
      <TrendIcon up={equityValue >= 0} color={color} />
      {spaceSeparatedThousands(equityValue)}%
    </span>
  );
};

export default EquityDisplay;
