import { FETCH_ADMIN_SETTINGS } from '../../actions/settings/admin';
import { FETCH_MASTER_SETTINGS } from '../../actions/settings/master';
import { FETCH_INVESTOR_SETTINGS } from '../../actions/settings/investor';
import { FETCH_CALLBACK_ADDRESSES } from '../../actions/settings/callbackAddresses';
import {
  FETCH_STATEMENT_PERIODS_SETTINGS,
  FETCH_REPORT_PERIODS_SETTINGS,
  FETCH_PAYMENT_PERIODS_SETTINGS
} from '../../actions/settings/periods';
import { FETCH_SERVER_SETTINGS } from '../../actions/settings/server';
import {
  AdminSettings,
  MasterSettings,
  InvestorSettings,
  MetaTraderServerSettings,
  RegularPeriodSettings,
  ServerSettingsResponse,
  PaymentPeriodSettings
} from '../../../types/settingsTypes';
import {
  FETCH_CONNECTED_MT_SERVERS,
  FETCH_MT_SERVERS_DISABLED,
  FETCH_MT_SERVERS_ENABLED,
  FETCH_MT_SERVERS_SHARED
} from '../../actions/settings/mtServers';

export interface SettingsState {
  adminSettings: AdminSettings;
  masterSettings: MasterSettings;
  investorSettings: InvestorSettings;
  mtServersSettings: Array<MetaTraderServerSettings>;
  mtServersSettingsDisabled: Array<MetaTraderServerSettings>;
  mtServersSettingsEnabled: Array<MetaTraderServerSettings>;
  mtServersSettingsShared: Array<MetaTraderServerSettings>;
  callbackAddresses: Array<string>;
  statementPeriodsSettings: Array<RegularPeriodSettings>;
  reportPeriodsSettings: Array<RegularPeriodSettings>;
  paymentPeriodsSettings: Array<PaymentPeriodSettings>;
  serverSettings: ServerSettingsResponse;
}

const initialState: SettingsState = {
  adminSettings: new AdminSettings(),
  masterSettings: new MasterSettings(),
  investorSettings: new InvestorSettings(),
  mtServersSettings: [new MetaTraderServerSettings()],
  mtServersSettingsDisabled: [new MetaTraderServerSettings()],
  mtServersSettingsEnabled: [new MetaTraderServerSettings()],
  mtServersSettingsShared: [],
  callbackAddresses: [],
  statementPeriodsSettings: [new RegularPeriodSettings()],
  reportPeriodsSettings: [new RegularPeriodSettings()],
  paymentPeriodsSettings: [new PaymentPeriodSettings()],
  serverSettings: {} as ServerSettingsResponse
};

// type FetchSettingsAction = FetchAdminSettingsAction | FetchMasterSettingsAction | FetchMasterSettingsAction;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const investorsReducer = (state: SettingsState = initialState, action: any): SettingsState => {
  switch (action.type) {
    case FETCH_ADMIN_SETTINGS:
      return {
        ...state,
        adminSettings: action.settings
      };
    case FETCH_MASTER_SETTINGS:
      return {
        ...state,
        masterSettings: action.settings
      };
    case FETCH_INVESTOR_SETTINGS:
      return {
        ...state,
        investorSettings: action.settings
      };
    case FETCH_CONNECTED_MT_SERVERS:
      return {
        ...state,
        mtServersSettings: action.settings
      };
    case FETCH_MT_SERVERS_DISABLED:
      return {
        ...state,
        mtServersSettingsDisabled: action.settings
      };
    case FETCH_MT_SERVERS_ENABLED:
      return {
        ...state,
        mtServersSettingsEnabled: action.settings
      };
    case FETCH_MT_SERVERS_SHARED:
      return {
        ...state,
        mtServersSettingsShared: action.settings
      };
    case FETCH_CALLBACK_ADDRESSES:
      return {
        ...state,
        callbackAddresses: action.addresses
      };
    case FETCH_STATEMENT_PERIODS_SETTINGS:
      return {
        ...state,
        statementPeriodsSettings: action.settings
      };
    case FETCH_REPORT_PERIODS_SETTINGS:
      return {
        ...state,
        reportPeriodsSettings: action.settings
      };
    case FETCH_PAYMENT_PERIODS_SETTINGS:
      return {
        ...state,
        paymentPeriodsSettings: action.settings
      };
    case FETCH_SERVER_SETTINGS:
      return {
        ...state,
        serverSettings: action.settings
      };
    default:
      return state;
  }
};

export default investorsReducer;
