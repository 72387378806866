interface IStatistics {
  Connected: boolean;
  StartTime: string;
  CurrentTime: string;
  TimeZone: string;
}

export enum checkComponentsValues {
  Statistics = 'Statistics',
  MT5Worker = 'MT5Worker',
  MT4Worker = 'MT4Worker',
  InvestorsTradeSync = 'InvestorsTradeSync'
}

export interface IMonitoringResponse {
  InvestorsTradeSync: Array<any>;
  MT4Worker: Array<any>;
  MT5Worker: Array<any>;
  Statistics: IStatistics;
}

export interface ISendReport {
  From: number;
  To: number;
  IncludeAllOpenOrders: boolean;
}

export interface ILoadOrders {
  ServerGuid: string;
  Tickets: Array<number | string>;
}

export interface IOrdersReload {
  ServerGuid: string;
  Orders: Array<number | string>;
}

export interface IOrderReopen {
  ServerGuid: string;
  Ticket: number | string;
}

export interface IOrdersCompare {
  Orders: Array<number | string>;
}

export interface IBalanceOperation {
  OperationDate: number;
  Amount: number;
  FixBalance: boolean;
  OperationId?: number;
}

export interface IPerformanceFeeCharge {
  Ids: any;
  CalcTime?: number;
}

export interface IOrderTicket {
  Ticket: any;
}

export interface IOrdersRedistribute {
  Mode: string;
  ReallocationParameters: Array<string>;
  Orders: Array<string | number>;
}

export interface IParametersToCheck {
  ParametersToCheck: Array<string>;
  CheckAll: boolean;
  AccountIds: Array<string | number>;
  Account?: string;
}

export interface IInvestorsCheck {
  ParametersToCheck?: Array<string>;
  CheckAll: boolean;
  InvestorIds?: Array<string | number>;
  ServerGuid?: string;
}

export interface IParametersToFix {
  ParametersToFix: Array<string>;
  FixAll: boolean;
  AccountIds: Array<string | number>;
  Account?: string;
}

export interface IPositionCheckFix {
  MmIds: Array<string | number>;
}

export enum ReallocationParametersValues {
  Volume = 'Volume',
  Profit = 'Profit',
  Commission = 'Commission',
  Tax = 'Tax',
  Swap = 'Swap'
}

export enum ParametersToCheckFixInvestors {
  All = 'All',
  Balance = 'Balance',
  Credit = 'Credit',
  Investments = 'Investments',
  CurrentTradeProfit = 'CurrentTradeProfit',
  TotalTradeProfit = 'TotalTradeProfit'
}

export enum ParametersToCheckFixMMs {
  All = 'All',
  Balance = 'Balance',
  Credit = 'Credit',
  TotalTradeProfit = 'TotalTradeProfit'
}

export enum ParametersToCheckInvestors {
  Balance = 'Balance',
  Credit = 'Credit'
}

export enum InvestorsCheckType {
  Parameters = 'Parameters',
  Positions = 'Positions'
}
