import { FETCH_SERVERS, FetchServersAction } from '../../actions/shared/fetchServers';
import { FETCH_CURRENCIES, FetchCurrenciesAction } from '../../actions/shared/fetchCurrencies';
import { Server } from '../../../types/sharedTypes';

export interface GeneralInfoState {
  servers: Array<Server>;
  currencies: Array<string>;
}

const initialState: GeneralInfoState = {
  servers: [],
  currencies: []
};

type GeneralInfoAction = FetchServersAction & FetchCurrenciesAction;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const generalInfoReducer = (state: GeneralInfoState = initialState, action: GeneralInfoAction): GeneralInfoState => {
  switch (action.type) {
    case FETCH_SERVERS:
      return {
        ...state,
        servers: action.servers
      };
    case FETCH_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies
      };
    default:
      return state;
  }
};

export default generalInfoReducer;
