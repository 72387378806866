import React from 'react';

interface TrendIconProps {
  up: boolean;
  color: string;
}

const TrendIcon: React.FC<TrendIconProps> = ({ up, color }): JSX.Element => {
  return up ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M2.3999 14.3998L6.81281 9.42203L10.5289 14.3998L16.7999 4.7998" stroke={color} strokeWidth="1.28" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 25" fill="none">
      <path d="M3 7.5L8.5 14L13.5 7.5L21 18" stroke={color} strokeWidth="1.6" />
    </svg>
  );
};
export default TrendIcon;
