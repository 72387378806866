import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { PAMMState } from '../../redux/reducers/rootReducer';

import styles from './styles/LanguageDropdown.module.css';

import selectArrow from '../../icons/select-icon.svg';

import englishIcon from '../../icons/English.svg';
import deutschIcon from '../../icons/Deutsch.svg';
import frenchIcon from '../../icons/French.svg';
import portugalIcon from '../../icons/Portugal.svg';
import spanishIcon from '../../icons/Spanish.svg';
import russianIcon from '../../icons/Russian.svg';
import arabianIcon from '../../icons/Arabian.svg';
import japanIcon from '../../icons/Japan.svg';
import koreanIcon from '../../icons/Korean.svg';
import georgiaIcon from '../../icons/Georgia.svg';
import chineseIcon from '../../icons/Chinese.svg';
import LocalStorageUtils from '../../utils/local-storage';
import { fetchLanguageJSONAsync } from '../../redux/actions/shared/fetchConfig';

const LanguageDropdown: React.FC<any> = (): JSX.Element => {
  const dispatch = useDispatch();

  const languagesList = useSelector((state: PAMMState) => state.configReducer.languages);
  const defaultLanguage: string = useSelector((state: PAMMState) => state.configReducer.defaultLanguage);
  const [currentLang, setLang] = useState<string>(
    (LocalStorageUtils.getValueFromLocalStorage('lang') as string) || defaultLanguage
  );

  const onLangChange = (langKey: string): void => {
    setLang(langKey);
    LocalStorageUtils.putValueToLocalStorage('lang', langKey);
    dispatch(fetchLanguageJSONAsync(langKey));
  };

  const getLanguagesFlags = (value: string): string => {
    switch (value) {
      case 'English':
        return englishIcon;
      case 'Deutsch':
        return deutschIcon;
      case 'Français':
        return frenchIcon;
      case 'Português':
        return portugalIcon;
      case 'Español':
        return spanishIcon;
      case 'ქართული':
        return georgiaIcon;
      case 'العربية':
        return arabianIcon;
      case '한국어':
        return koreanIcon;
      case 'Русский':
        return russianIcon;
      case '日本語':
        return japanIcon;
      case '中文':
        return chineseIcon;
      default:
        return '';
    }
  };

  const languagesItems = Object.keys(languagesList)
    .filter((lang: string) => lang !== currentLang)
    .map((lang: string, index: number) => {
      return (
        <Dropdown.Item
          key={`${lang}-${index}`}
          onClick={() => onLangChange(lang)}
          bsPrefix={`${styles['dropdown-item']} text-regular`}
          className="pt-2 pb-2"
          style={{ paddingLeft: 14 }}
        >
          <img
            src={getLanguagesFlags(languagesList[lang])}
            className={styles['flags-img']}
            height="20px"
            width="20px"
            alt="no flag"
          />
          {languagesList[lang]}
        </Dropdown.Item>
      );
    });

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle id="languages-dropdown" className={`${styles['language-dropdown']} text-regular`}>
          <img
            className={styles['flags-img']}
            src={getLanguagesFlags(languagesList[currentLang])}
            height="20px"
            width="20px"
            alt="no flag"
          />
          <span className={styles['languages-dropdown-button-text']}>{languagesList[currentLang] || languagesList.en}</span>
          <img className="pb-1" src={selectArrow} alt="no arrow" />
        </Dropdown.Toggle>
        <Dropdown.Menu id="languages-dropdown-container" className={styles['dropdown-menu']}>
          {languagesItems}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default LanguageDropdown;
