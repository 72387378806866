/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { AppNavbar, AppSideBar, IconItem } from '@t4b/core';
import { ToastContainer } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { History, Location } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Modal, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faBook,
  faChartArea,
  faCogs,
  faDesktop,
  faExchangeAlt,
  faFileLines,
  faHandHoldingDollar,
  faList,
  faNetworkWired,
  faPercent,
  faSignOutAlt,
  faUserPlus,
  faUsers,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import GenUserNavbar from './components/generalUser/GenUserNavbar';
import GenUserTopbar from './components/generalUser/GenUserTopbar';
import { APP_LOGO } from './const/logo.const';
import LocalStorageUtils from './utils/local-storage';
import { logoutUserAsync, removeJwt } from './redux/actions/auth';
import fetchConfigAsync, { fetchLanguageJSONAsync } from './redux/actions/shared/fetchConfig';
import AppRoutes from './components/routes/Routes';
import BottomBarMobile from './components/mobile/BottomBarMobile';
import { PAMMState } from './redux/reducers/rootReducer';
import OperationsMenuMobile from './components/mobile/OperationsMenuMobile';
import LinkedAccountsMenu from './components/mobile/LinkedAccountsMenuMobile';
import LanguagesMenu from './components/mobile/LanguagesMenuMobile';
import ConfirmConnectToMasterMenu from './components/mobile/ConfirmConnectToMasterMenuMobile';
import {
  setConnectToMasterConfirmationStatus,
  setDisconnectFromMasterConfirmationStatus,
  setLanguagesMenuStatus,
  setLinkedAccountsMenuStatus,
  setOpsMenuStatus
} from './redux/actions/setMobileMenusStatus';
import { Investor } from './types/investorTypes';
import { AccountType } from './types/authTypes';
import { getOperationsMenuHeight } from './utils/operationsMenuMobile';
import ConfirmDisconnectFromMasterMenu from './components/mobile/ConfirmDisconnectFromMasterMenuMobile';
import getLinkedAccountsMenuMobileHeight from './utils/linkedAccountsMenuMobile';
import ToastErrorIcon from './icons/ToastErrorIcon';
import ToastSuccessIcon from './icons/ToastSuccessIcon';
import StorePopupModal from './components/shared/StorePopupModal';
import useShowMobileVersion from './hooks/useShowMobileVersion';
import customerPortalIcon from './icons/customer-portal-icon.svg';

const App: React.FC = () => {
  const dispatch: any = useDispatch();
  const history: History = useHistory();
  const location: Location = useLocation();
  const showMobileVersion = useShowMobileVersion();

  const langJSON = useSelector((state: PAMMState) => state.configReducer.langFile);

  // START "AppNavbar" related
  const api = useSelector((state: PAMMState) => state.configReducer.api);
  const languagesList = useSelector((state: PAMMState) => state.configReducer.languages);
  const copyright: string = useSelector((state: PAMMState) => state.configReducer.copyright);
  const defaultLanguage: string = useSelector((state: PAMMState) => state.configReducer.defaultLanguage);
  const customerPortalLink: string = useSelector((state: PAMMState) => state.configReducer.customerPortalLink);

  const [sidebarHidden, setSidebarHidden] = useState<boolean>(true);
  const isAuthenticated = LocalStorageUtils.getValueFromLocalStorage('isAuthenticated') === 'true';

  const [tokenState, setTokenState] = useState(false);

  const handleSidebarClick = () => setSidebarHidden(!sidebarHidden);
  const userJSON: string | null = LocalStorageUtils.getValueFromLocalStorage('user');
  const currentLang: string | null = LocalStorageUtils.getValueFromLocalStorage('lang');
  // this shouldn't be like this
  const investors: Array<Investor> = useSelector((state: PAMMState) => state.investorsReducer.investors);
  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);

  const closedStorePopupModal = localStorage.getItem('closedStorePopupModal');

  let user: any;

  if (typeof userJSON === 'string') {
    user = JSON.parse(userJSON);
  }

  useEffect(() => {
    if (defaultLanguage) {
      dispatch(fetchLanguageJSONAsync(currentLang || defaultLanguage));
    }
  }, [defaultLanguage]);

  const isTokenExpired = (): any => {
    if (Number(LocalStorageUtils.getValueFromLocalStorage('exp')) < Date.now()) {
      setTimeout(() => {
        setTokenState(true);
        isTokenExpired();
      }, 360000);
      return true;
    }

    setTimeout(() => {
      setTokenState(false);
      isTokenExpired();
    }, 360000);
    return false;
  };

  const tokenExpired: boolean = isTokenExpired();
  useEffect(() => {
    if (isAuthenticated && tokenExpired) {
      dispatch(logoutUserAsync(api, history, true));
    }
  }, [tokenState]);

  useEffect(() => {
    dispatch(fetchConfigAsync());
  }, [dispatch]);

  const onLangChange = (langKey: string): void => {
    LocalStorageUtils.putValueToLocalStorage('lang', langKey);
    dispatch(fetchLanguageJSONAsync(langKey));

    if (showMobileVersion) {
      dispatch(setLanguagesMenuStatus(false));
    }
  };

  const signOutFunc = () => {
    dispatch(logoutUserAsync(api, history));
    removeJwt();
  };

  const exitButton: JSX.Element = (
    <Nav.Link key="exitBtn" className="pamm-navbar-exit pl-0" onClick={signOutFunc} data-testid="navbar-exit">
      <FontAwesomeIcon size="lg" color="white" icon={faSignOutAlt} />
    </Nav.Link>
  );

  const customerPortalButton: JSX.Element = (
    <a href={customerPortalLink} target="_blank" rel="noopener noreferrer" className="pamm-navbar-customer-portal pl-0">
      <img src={customerPortalIcon} alt="customer-portal-icon" />
    </a>
  );
  // END

  // START "AppSideBar" related
  const [showModal, setShowModal] = useState<boolean>(false);

  const sidebarItems = [
    {
      translateKey: 'dashboard',
      isExternal: false,
      link: '/dashboard',
      icon: new IconItem('48px', faChartArea, 'lg', '10px', '#43A09A'),
      tooltipKey: <FormattedMessage id="dashboard" />
    },
    {
      translateKey: 'investors.title',
      isExternal: false,
      link: '/investors',
      icon: new IconItem('48px', faUsers, 'lg', '10px', '#FFFFFF'),
      tooltipKey: <FormattedMessage id="investors.title" />
    },
    {
      translateKey: 'masters.title',
      isExternal: false,
      link: '/money_managers',
      icon: new IconItem('48px', faHandHoldingDollar, 'lg', '10px', '#FFFFFF'),
      tooltipKey: <FormattedMessage id="masters.title" />
    },
    {
      translateKey: 'admins',
      isExternal: false,
      link: '/admins',
      icon: new IconItem('48px', faUserTie, 'lg', '10px', '#FFFFFF'),
      tooltipKey: <FormattedMessage id="admins" />
    },
    {
      translateKey: 'feeAccounts.title',
      isExternal: false,
      link: '/fee-accounts',
      icon: new IconItem('48px', faPercent, 'lg', '10px', '#FFFFFF'),
      tooltipKey: <FormattedMessage id="feeAccounts.title" />
    },
    {
      translateKey: 'create',
      isExternal: false,
      link: '/create/investor',
      icon: new IconItem('48px', faUserPlus, 'lg', '10px', '#70BBD0'),
      tooltipKey: <FormattedMessage id="create" />
    },
    {
      translateKey: 'investorsAttachDetach',
      isExternal: false,
      link: '/mass-attach/investors',
      icon: new IconItem('48px', faNetworkWired, 'lg', '10px', '#70BBD0'),
      tooltipKey: <FormattedMessage id="investorsAttachDetach" />
    },
    {
      translateKey: 'investors.report',
      isExternal: false,
      link: '/investorsReport',
      icon: new IconItem('48px', faFileLines, 'lg', '10px', '#2C869E'),
      tooltipKey: <FormattedMessage id="investors.report" />
    },
    {
      translateKey: 'orders.title',
      isExternal: false,
      link: '/orders',
      icon: new IconItem('48px', faList, 'lg', '10px', '#2C869E'),
      tooltipKey: <FormattedMessage id="orders.title" />
    },
    {
      translateKey: 'journal',
      isExternal: false,
      link: '/journal',
      icon: new IconItem('48px', faBook, 'lg', '10px', '#2C869E'),
      tooltipKey: <FormattedMessage id="journal" />
    },
    {
      translateKey: 'requestsNomPlur',
      isExternal: false,
      link: '/requests',
      icon: new IconItem('48px', faExchangeAlt, 'lg', '10px', '#2C869E'),
      tooltipKey: <FormattedMessage id="requestsNomPlur" />
    },
    {
      translateKey: 'administrator.monitoring',
      isExternal: false,
      link: '/monitoring',
      icon: new IconItem('48px', faDesktop, 'lg', '10px', '#FFFFFF'),
      tooltipKey: <FormattedMessage id="administrator.monitoring" />
    },
    {
      translateKey: 'settings',
      isExternal: false,
      link: '/settings/common',
      icon: new IconItem('48px', faCogs, 'lg', '10px', '#43A09A'),
      tooltipKey: <FormattedMessage id="settings" />
    }
  ];

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const licenceModal = (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="licenceModal.title" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>PAMM.Frontend.3.31</p>
        <p>{copyright}</p>
      </Modal.Body>
    </Modal>
  );

  const sidebarComponent =
    isAuthenticated && !tokenExpired ? (
      <AppSideBar
        sidebarItems={sidebarItems}
        logo={faBars}
        progressItems={[]}
        sidebarHidden={sidebarHidden}
        currentLocation={location.pathname}
        onLicenceClick={handleShowModal}
        isScrollable
      />
    ) : null;
  // END

  const showOpsMenu = useSelector((state: PAMMState) => state.mobileMenusStatusReducer.showOpsMenu);
  const showLinkedAccountsMenu = useSelector((state: PAMMState) => state.mobileMenusStatusReducer.showlinkedAccountsMenu);
  const showLanguagesMenu = useSelector((state: PAMMState) => state.mobileMenusStatusReducer.showLanguagesMenu);
  const showConfirmConnectToMasterMenu = useSelector(
    (state: PAMMState) => state.mobileMenusStatusReducer.showConnectToMasterConfirmation
  );
  const showConfirmDisconnectFromMasterMenu = useSelector(
    (state: PAMMState) => state.mobileMenusStatusReducer.showDisconnectFromMasterConfirmation
  );

  let touches: any = [];

  const handleSwipe = (event: any) => {
    touches.push(event.pageY);
  };

  const handleCancel = (menu: string): void => {
    if (touches[0] < touches[touches.length - 1]) {
      if (menu === 'operationsMenu') {
        dispatch(setOpsMenuStatus(false));
      } else if (menu === 'linkedAccountsMenu') {
        dispatch(setLinkedAccountsMenuStatus(false));
      } else if (menu === 'languagesMenu') {
        dispatch(setLanguagesMenuStatus(false));
      } else if (menu === 'confirmConnectToMasterMenu') {
        dispatch(setConnectToMasterConfirmationStatus(false));
      } else if (menu === 'confirmDisconnectFromMasterMenu') {
        dispatch(setDisconnectFromMasterConfirmationStatus(false));
      }
    }

    touches = [];
  };

  const handleCancelSingleTouch = (): void => {
    if (showOpsMenu) {
      dispatch(setOpsMenuStatus(false));
    } else if (showLinkedAccountsMenu) {
      dispatch(setLinkedAccountsMenuStatus(false));
    } else if (showLanguagesMenu) {
      dispatch(setLanguagesMenuStatus(false));
    } else if (showConfirmConnectToMasterMenu) {
      dispatch(setConnectToMasterConfirmationStatus(false));
    } else if (showConfirmDisconnectFromMasterMenu) {
      dispatch(setDisconnectFromMasterConfirmationStatus(false));
    }
  };

  const shadow = (
    // eslint-disable-next-line react/self-closing-comp
    <div className="shadow shadow-active" style={{ zIndex: 5000 }}></div>
  );

  const showSidebar = (): boolean => {
    if (user && isAuthenticated && !tokenExpired) {
      if (user.AccountType !== AccountType.Admin) {
        return false;
      }

      return true;
    }

    return false;
  };

  const showAdminNavbar = (): boolean => {
    if (user && isAuthenticated && !tokenExpired) {
      if (!showMobileVersion && user.AccountType === AccountType.Admin) {
        return true;
      }
      if (showMobileVersion && user.AccountType === AccountType.Admin) {
        return true;
      }
    }

    return false;
  };

  const showGeneralUserNavbar = (): boolean => {
    if (user && isAuthenticated && !tokenExpired) {
      if (!showMobileVersion && user.AccountType !== AccountType.Admin) {
        return true;
      }
    }

    return false;
  };

  const showBottomBar = (): boolean => {
    if (user && isAuthenticated && !tokenExpired) {
      if (showMobileVersion && user.AccountType !== AccountType.Admin) {
        return true;
      }
    }

    return false;
  };

  const getAppStyle = (): string => {
    if ((user && user.AccountType === AccountType.Admin) || !user) {
      return 'm-4 p-2';
    }

    return '';
  };

  const overlayscrollbarsOptions = {
    overflowBehavior: {
      x: 'hidden'
    },
    scrollbars: {
      autoHide: 'scroll',
      autoHideDelay: 1000
    }
  } as OverlayScrollbars.Options;

  const mainClass =
    user === undefined || user.AccountType === AccountType.Investor || user.AccountType === AccountType.Master ? 'main' : '';

  return api && Object.keys(langJSON).length !== 0 && defaultLanguage ? (
    <IntlProvider locale={currentLang || defaultLanguage} messages={langJSON}>
      <div className={mainClass}>
        {showAdminNavbar() ? (
          <AppNavbar
            logo={APP_LOGO}
            i18nLangs={languagesList}
            currentLangKey={currentLang || defaultLanguage}
            isAuthenticated={isAuthenticated && !tokenExpired}
            onLangChange={onLangChange}
            onSidebarHandlerClick={handleSidebarClick}
            exitButton={exitButton}
            customerPortalButton={customerPortalLink ? customerPortalButton : undefined}
          />
        ) : null}
        {showBottomBar() ? <BottomBarMobile user={user} /> : null}
        <div
          className={`d-flex justify-content-center${sidebarHidden ? '' : ' sidebar-show'}`}
          onPointerDown={handleCancelSingleTouch}
        >
          {showAdminNavbar() && closedStorePopupModal !== 'closed' ? <StorePopupModal /> : null}
          {licenceModal}
          {showSidebar() ? sidebarComponent : null}
          {/* eslint-disable-next-line no-nested-ternary */}
          {(showMobileVersion && !user) ||
          (showMobileVersion && user?.AccountType === 'investor') ||
          (showMobileVersion && user?.AccountType === 'master') ? (
            <>
              <OverlayScrollbarsComponent
                options={overlayscrollbarsOptions}
                style={{ height: 'calc(100vh - 58px)', width: '100%' }}
              >
                <div className="m-4 p-2">
                  <AppRoutes isAuthenticated={isAuthenticated && !tokenExpired} />
                </div>
              </OverlayScrollbarsComponent>
              {showOpsMenu ||
              showLinkedAccountsMenu ||
              showLanguagesMenu ||
              showConfirmConnectToMasterMenu ||
              showConfirmDisconnectFromMasterMenu
                ? shadow
                : null}
            </>
          ) : user?.AccountType === 'admin' ? (
            <OverlayScrollbarsComponent className="admin-bg-color" style={{ height: 'calc(100vh - 58px)', width: '100%' }}>
              <div className={getAppStyle()} style={{ minWidth: '1146px' }}>
                <AppRoutes isAuthenticated={isAuthenticated && !tokenExpired} currentLang={currentLang || ''} />
              </div>
            </OverlayScrollbarsComponent>
          ) : (
            <div style={{ width: '100%', display: 'flex', minHeight: '100dvh' }}>
              {showGeneralUserNavbar() ? (
                <>
                  <GenUserNavbar
                    signOutFunc={signOutFunc}
                    currentLang={currentLang || defaultLanguage}
                    languages={languagesList}
                    onLangChange={onLangChange}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      minWidth: 'calc(100% - 444px)',
                      width: '100%',
                      padding: `${isAuthenticated ? '0px 30px 0px 30px' : ''}`
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <GenUserTopbar signOutFunc={signOutFunc} />
                      <AppRoutes isAuthenticated={isAuthenticated && !tokenExpired} />
                    </div>
                    <div className="text-small" style={{ margin: '48px 0px' }}>
                      {copyright}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    minWidth: '77%',
                    width: '100%',
                    padding: `${isAuthenticated ? '0px 30px 0px 30px' : ''}`
                  }}
                >
                  <AppRoutes isAuthenticated={isAuthenticated && !tokenExpired} />
                </div>
              )}
            </div>
          )}
        </div>
        <ToastContainer
          icon={({ theme, type }: any) => (type === 'error' ? <ToastErrorIcon /> : <ToastSuccessIcon />)}
          theme="colored"
        />
        <OperationsMenuMobile
          // eslint-disable-next-line no-nested-ternary
          menuHeight={
            showOpsMenu
              ? user?.AccountType === 'investor'
                ? getOperationsMenuHeight(user?.AccountType, investor?.AccountOptions)
                : getOperationsMenuHeight(user?.AccountType, user?.AccountInfo?.AccountOptions)
              : 0
          }
          onSwipe={handleSwipe}
          onCancel={handleCancel}
          currentLang={currentLang || defaultLanguage}
        />
        {investors?.length > 0 ? (
          <LinkedAccountsMenu
            menuHeight={showLinkedAccountsMenu ? getLinkedAccountsMenuMobileHeight(investors?.length - 1) : 0}
            onSwipe={handleSwipe}
            onCancel={handleCancel}
          />
        ) : null}
        <LanguagesMenu
          menuHeight={showLanguagesMenu ? 690 : 0}
          onSwipe={handleSwipe}
          onCancel={handleCancel}
          onLangChange={onLangChange}
        />
        <ConfirmConnectToMasterMenu
          menuHeight={showConfirmConnectToMasterMenu ? 210 : 0}
          onSwipe={handleSwipe}
          onCancel={handleCancel}
        />
        <ConfirmDisconnectFromMasterMenu
          menuHeight={showConfirmDisconnectFromMasterMenu ? 250 : 0}
          onSwipe={handleSwipe}
          onCancel={handleCancel}
        />
      </div>
    </IntlProvider>
  ) : null;
};

export default App;
