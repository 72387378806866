/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { spaceSeparatedThousands } from '../../utils/number';
import styles from './investor/cssModules/GenUserLinkedAccountsListItem.module.css';
import { Investor } from '../../types/investorTypes';
import { PAMMState } from '../../redux/reducers/rootReducer';
import WalletNavBarIcon from '../../icons/WalletNavBarIcon';
import EquityDisplay from '../shared/EquityDisplay';
import { Summary, UserProfitAllTime } from '../../types/sharedTypes';
import { VisibilityExtendedToggle } from '../../types/toggles';

interface GenUserLinkedAccountsListItemProps {
  linkedAccount: Investor;
  expandedMenu: boolean;
}

const GenUserLinkedAccountsListItem: React.FC<GenUserLinkedAccountsListItemProps> = ({
  linkedAccount,
  expandedMenu
}): JSX.Element => {
  const profitInvestor: Array<Summary> = useSelector((state: PAMMState) => state.profitReducer.profit);

  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);
  const userProfits = useSelector((state: PAMMState) => state.profitReducer.arrayOfProfitsAllTime);
  const userProfitAllTime = userProfits.filter((userProfit: UserProfitAllTime) => userProfit.userId === linkedAccount.Id)[0];

  const isSelected = linkedAccount?.Id === investor?.Id;
  const selectedColorStyle = isSelected ? 'selectedId' : 'secondId';

  const isOpenOrdersVisible =
    investor.AccountOptions.InvestorOpenOrdersTab === VisibilityExtendedToggle.Show ||
    investor.AccountOptions.InvestorOpenOrdersTab === VisibilityExtendedToggle.OnlyProfit;

  return (
    <>
      {expandedMenu ? (
        <div className={styles[`${selectedColorStyle}`]}>
          <div className={`${styles['auto-layout-vertical']} me-2`}>
            <div className={styles['div']}>
              <span>
                <WalletNavBarIcon size={16} />
              </span>
              <div className={`${styles['text-wrapper']} text-small-semibold`}>{linkedAccount.Name}</div>
            </div>
            <div className={`${styles[`text-wrapper-2-${selectedColorStyle}`]} text-inputs`}>
              <span style={{ textTransform: 'lowercase' }}>
                <FormattedMessage id="id" />:
              </span>
              <span>&nbsp;</span>
              <span>{linkedAccount.Id}</span>
            </div>
          </div>
          <div className={styles['auto-layout-vertical-2']}>
            <div className={`${styles[`text-wrapper-3-${selectedColorStyle}`]} text-semibold`}>
              {linkedAccount.Currency} {isOpenOrdersVisible && spaceSeparatedThousands(linkedAccount.Equity)}
            </div>
            {investor.LinkToken ? (
              <EquityDisplay equityValue={userProfitAllTime?.profitFortnightAllTime[0]?.Value} styleName="div-2" isSelected={isSelected} />
            ) : (
              <EquityDisplay equityValue={profitInvestor[0]?.Value} styleName="div-2" isSelected={isSelected} />
            )}
          </div>
        </div>
      ) : (
        <div className={styles[`${selectedColorStyle}-small`]}>
          <div className={styles['auto-layout-vertical']}>
            <div className={styles['div']}>
              <span>
                <WalletNavBarIcon size={12} />
              </span>
              <div className={`${styles['text-wrapper']} text-small-semibold`}>{linkedAccount.Currency}</div>
            </div>
          </div>
          <div className={`${styles[`text-wrapper-5-${selectedColorStyle}`]} text-inputs`}>
            {spaceSeparatedThousands(linkedAccount?.Equity)}
          </div>
        </div>
      )}
    </>
  );
};

export default GenUserLinkedAccountsListItem;
