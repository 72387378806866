/* eslint-disable no-restricted-globals */
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PAMMState } from '../../redux/reducers/rootReducer';
import { Investor } from '../../types/investorTypes';
import GenUserLinkedAccountsListItem from './GenUserLinkedAccountsListItem';
import LocalStorageUtils from '../../utils/local-storage';

interface GenUserLinkedAccountsListProps {
  linkedAccounts: Array<Investor>;
  expandedMenu: boolean;
  isOpen: boolean;
}

const GenUserLinkedAccountsList: React.FC<GenUserLinkedAccountsListProps> = ({
  linkedAccounts,
  expandedMenu,
  isOpen
}): JSX.Element => {
  const userJSON = JSON.parse(LocalStorageUtils.getValueFromLocalStorage('user') || '');

  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);

  const accountsToDisplay = isOpen ? [] : linkedAccounts;
  const displayAccounts = userJSON.LinkToken ? accountsToDisplay : [investor];

  const linkedAccountsLinks = displayAccounts.map((linkedAccount: Investor) => {
    return (
      <div key={linkedAccount?.Id}>
        <Link to={`/dashboard/${linkedAccount?.Id}`}>
          <div className="pb-2">
            <GenUserLinkedAccountsListItem linkedAccount={linkedAccount} expandedMenu={expandedMenu} />
          </div>
        </Link>
      </div>
    );
  });

  return <>{linkedAccountsLinks}</>;
};
export default GenUserLinkedAccountsList;
