/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { createRequestToAttachInvestor } from '../../redux/actions/investor/attachInvestor';
import { PAMMState } from '../../redux/reducers/rootReducer';
import { Investor } from '../../types/investorTypes';
import { Master } from '../../types/masterTypes';
import { setConnectToMasterConfirmationStatus } from '../../redux/actions/setMobileMenusStatus';
import styles from './OperationsMenuMobile.module.css';

const ConfirmConnectToMasterMenu: React.FC<any> = ({ menuHeight = 0, onSwipe, onCancel }): JSX.Element => {
  const dispatch = useDispatch();
  const history: History = useHistory();
  const customColor = useSelector((state: PAMMState) => state.configReducer.customColor);

  const style = {
    width: '100%',
    height: menuHeight,
    position: 'fixed',
    zIndex: 9999,
    bottom: '-5rem',
    backgroundColor: 'white',
    transition: '0.5s',
    padding: '1.5rem',
    fontSize: '1.3rem'
  } as React.CSSProperties;

  const buttonStyle = {
    backgroundColor: customColor,
    color: 'white',
    border: 0,
    borderRadius: 10,
    fontSize: 13,
    paddingBottom: 7,
    WebkitBoxShadow: '0px 0px 7px 2px rgba(0,0,0,0.1)',
    boxShadow: '0px 0px 7px 2px rgba(0,0,0,0.1)',
    width: '70%'
  } as React.CSSProperties;

  const api: string = useSelector((state: PAMMState) => state.configReducer.api);
  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);
  const master: Master = useSelector((state: PAMMState) => state.masterReducer.master);

  const handleAttach = (): void => {
    dispatch(createRequestToAttachInvestor(api, investor.Id.toString(), master.Id.toString(), history));
    dispatch(setConnectToMasterConfirmationStatus(false));
  };

  return (
    <div
      id="confirm-connect-to-master-menu"
      style={style}
      onPointerMove={(event) => onSwipe(event)}
      onPointerCancel={() => onCancel('confirmConnectToMasterMenu')}
    >
      <h6 className="d-flex justify-content-center">
        <FormattedMessage id="masterDetails.mobile.connectToThisMaster" />?
      </h6>
      <div className={`${styles['topMargin']} d-flex justify-content-center`}>
        <Button style={buttonStyle} onClick={handleAttach}>
          <FormattedMessage id="masterDetails.mobile.connectToThisMaster" />
        </Button>
      </div>
    </div>
  );
};

export default ConfirmConnectToMasterMenu;
