import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { IOrdersRedistribute } from '../../../types/maintenanceTypes';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_ORDERS_REDISTRIBUTE = 'FETCH_ORDERS_REDISTRIBUTE';

export type OrdersRedistributeAction = {
  type: string;
  ordersRedistribute: IOrdersRedistribute;
};

const fetchOrdersCompare = (ordersRedistribute: IOrdersRedistribute): OrdersRedistributeAction => {
  return {
    type: FETCH_ORDERS_REDISTRIBUTE,
    ordersRedistribute
  };
};

const fetchOrdersRedistributeAsync = (api: string, request: IOrdersRedistribute) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/maintenance/orders/redistribute`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((ordersRedistribute: any) => {
        dispatch(fetchOrdersCompare(ordersRedistribute));
        toast.success('Load Orders successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchOrdersRedistributeAsync;
