import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styles from '../shared/styles/GenUserFeedbackPopupModal.module.css';
import tfbStoreClose from '../../icons/tfb-store-close.svg';
import link from '../../icons/link.svg';
import { PAMMState } from '../../redux/reducers/rootReducer';
import { PopupStatus } from '../../types/sharedTypes';

const GenUserFeedbackPopupModal: React.FC = () => {
  const redirectButton: string | undefined = useSelector(
    (state: PAMMState) => state.configReducer?.feedbackPopupModal?.redirectButton
  );

  const showPopup: boolean = useSelector((state: PAMMState) => state.configReducer.feedbackPopupModal.showPopup);

  const [isOpen, setIsOpen] = React.useState(showPopup);

  const closeModal = () => {
    localStorage.setItem('feedbackPopupStatus', PopupStatus.Closed.valueOf());
    setIsOpen(false);
  };

  const handleRedirect = (url: string) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
      closeModal();
    }
  };

  return isOpen ? (
    <div className={styles['popup']}>
      <div className="d-flex justify-content-end">
        <img className={styles['close-btn']} src={tfbStoreClose} alt="Close" onClick={closeModal} />
      </div>
      <div className={`${styles['popup-title']} text-semibold`}>
        <FormattedMessage id="feedback.title" />
      </div>
      <div className={`${styles['popup-body']} text-small`}>
        <FormattedMessage id="feedback.body" />
      </div>
      <button className={`${styles['open-btn']} text-small`} onClick={() => handleRedirect(redirectButton)}>
        <FormattedMessage id="feedback.openButton" />
        <img className={styles['open-img']} src={link} alt="Link" />
      </button>
    </div>
  ) : (
    <></>
  );
};

export default GenUserFeedbackPopupModal;
