/* eslint-disable no-case-declarations */
import {
  FETCH_PROFIT,
  FETCH_PROFIT_PER_PERIOD,
  FETCH_ARRAY_OF_PROFITS,
  FetchProfitAction,
  FetchArrayOfProfitsAction,
  FETCH_PROFIT_OVERALL,
  FETCH_PROFIT_MASTER,
  FETCH_ARRAY_OF_PROFITS_ALL_TIME,
  FetchArrayOfProfitsAllTimeAction,
  FETCH_PROFIT_OWNFUNDS
} from '../../actions/shared/fetchProfit';
import { Summary, UserProfitAllTime } from '../../../types/sharedTypes';
import { MasterProfit } from '../../../types/masterTypes';

export interface ProfitState {
  profit: Array<Summary>;
  profitMaster: Array<Summary>;
  profitOwnFunds: Array<Summary>;
  profitPerPeriod: Array<Summary>;
  profitOverall: Array<Summary>;
  arrayOfProfits: Array<MasterProfit>;
  arrayOfProfitsAllTime: Array<UserProfitAllTime>;
}

const initialState: ProfitState = {
  profit: [],
  profitMaster: [],
  profitOwnFunds: [],
  profitPerPeriod: [],
  profitOverall: [],
  arrayOfProfits: [],
  arrayOfProfitsAllTime: []
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const profitReducer = (
  state: ProfitState = initialState,
  action: FetchProfitAction & FetchArrayOfProfitsAction & FetchArrayOfProfitsAllTimeAction
): ProfitState => {
  switch (action.type) {
    case FETCH_PROFIT:
      return {
        ...state,
        profit: action.profitFortnight
      };
    case FETCH_PROFIT_MASTER:
      return {
        ...state,
        profitMaster: action.profitFortnight
      };
    case FETCH_PROFIT_OWNFUNDS:
      return {
        ...state,
        profitOwnFunds: action.profitFortnight
      };

    case FETCH_PROFIT_PER_PERIOD:
      return {
        ...state,
        profitPerPeriod: action.profitFortnight
      };
    case FETCH_PROFIT_OVERALL:
      return {
        ...state,
        profitOverall: action.profitFortnight
      };
    case FETCH_ARRAY_OF_PROFITS:
      const { masterId, profitFortnight } = action;

      let found = false;

      const updatedArrayOfProfits = state.arrayOfProfits.map((profit) => {
        if (profit.masterId === masterId) {
          found = true;
          return { ...profit, profitFortnight };
        }
        return profit;
      });

      if (!found) {
        updatedArrayOfProfits.push({ masterId, profitFortnight });
      }

      return {
        ...state,
        arrayOfProfits: updatedArrayOfProfits
      };
    case FETCH_ARRAY_OF_PROFITS_ALL_TIME:
      const { userId, profitFortnightAllTime } = action;

      let foundAllTime = false;

      const updatedArrayOfProfitsAllTime = state.arrayOfProfitsAllTime.map((profit) => {
        if (profit.userId === userId) {
          foundAllTime = true;
          return { ...profit, profitFortnightAllTime };
        }
        return profit;
      });

      if (!foundAllTime) {
        updatedArrayOfProfitsAllTime.push({ userId, profitFortnightAllTime });
      }

      return {
        ...state,
        arrayOfProfitsAllTime: updatedArrayOfProfitsAllTime
      };
    default:
      return state;
  }
};

export default profitReducer;
