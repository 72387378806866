/* eslint-disable no-shadow */
import { History } from 'history';
import { Dispatch } from 'redux';
import Cookies from 'universal-cookie';
import headersBuilder, { Method } from '../../utils/request';
import LocalStorageUtils from '../../utils/local-storage';
import Errors from '../../shared/validation/errors';
import SERVICE_API from '../../api';

const cookies = new Cookies();

export function getJwt(): string | null {
  return cookies.get('jwt');
}

export function removeJwt() {
  cookies.remove('jwt');
}

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_RESET_STATE = 'LOGIN_RESET_STATE';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export type AuthAction = {
  type: string;
  user?: any;
};
const loginUserAC = (user: any): AuthAction => ({ type: LOGIN, user });

const logoutUserAC = (): AuthAction => ({ type: LOGOUT });

const loginStartAC = (): AuthAction => ({ type: LOGIN_START });

const loginSuccessAC = (): AuthAction => ({ type: LOGIN_SUCCESS });

const loginResetStateAC = (): AuthAction => ({ type: LOGIN_RESET_STATE });

const loginUserAsync = (
  api: string,
  username: string,
  password: string,
  rememberMe: boolean,
  history: History,
  MtServerId?: number | null,
  Mode?: number | string
) => {
  return (dispatch: Dispatch): Promise<any> => {
    const maxAttempts = 1;
    let attemptCount = 0;

    const loginRequest = (mode?: number | string): any => {
      return fetch(
        `${api}/auth/login`,
        headersBuilder(Method.POST, {
          Username: username,
          Password: password,
          rememberMe,
          MtServerId,
          Mode: mode
        })
      )
        .then((response: Response) => Errors.checkResponseForErrors(response, api))
        .then((user: any) => {
          cookies.set('jwt', user.Token, { path: '/', maxAge: user.ExpiresIn * 1000 });
          LocalStorageUtils.putValueToLocalStorage('exp', (Date.now() + user.ExpiresIn * 1000).toString());

          delete user.Token;
          LocalStorageUtils.putValueToLocalStorage('accountType', user.AccountType);
          LocalStorageUtils.putValueToLocalStorage('user', JSON.stringify(user));
          const rememberURL = LocalStorageUtils.getValueFromLocalStorage('rememberPage');
          dispatch(loginUserAC(user));
          LocalStorageUtils.putValueToLocalStorage('isAuthenticated', 'true');
          // window.location.reload()
          return rememberURL;
        })
        .then((rememberURL) => {
          const currentURL = window.location.pathname;
          const accountType = LocalStorageUtils.getValueFromLocalStorage('accountType');

          if (rememberURL && accountType === JSON.parse(rememberURL).accountType && currentURL === '/login') {
            history.push(JSON.parse(rememberURL).page);
          } else if (currentURL !== '/login') {
            history.push(currentURL);
          } else {
            history.push('/dashboard');
          }
        })
        .catch((error: Error) => {
          if (error.message.includes('Authorization failed') && attemptCount < maxAttempts && !MtServerId) {
            attemptCount++;
            return loginRequest(undefined);
          }
          return Errors.throwErrorMessage(error);
        });
    };

    return loginRequest(Mode);
  };
};

const loginUserSSOAsync = (api: string, token: string, history: History) => {
  return (dispatch: Dispatch): void => {
    fetch(
      `${api}/auth/sso/loginByToken`,
      headersBuilder(Method.POST, {
        Token: token
      })
    )
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((user: any) => {
        cookies.set('jwt', user.Token, { path: '/', maxAge: user.ExpiresIn * 1000 });
        LocalStorageUtils.putValueToLocalStorage('exp', (Date.now() + user.ExpiresIn * 1000).toString());
        delete user.Token;
        LocalStorageUtils.putValueToLocalStorage('accountType', user.AccountType);
        LocalStorageUtils.putValueToLocalStorage('user', JSON.stringify(user));
        const rememberURL = LocalStorageUtils.getValueFromLocalStorage('rememberPage');
        dispatch(loginUserAC(user));
        LocalStorageUtils.putValueToLocalStorage('isAuthenticated', 'true');
        return rememberURL;
      })
      .then((rememberURL) => {
        const currentURL = window.location.pathname;
        const accountType = LocalStorageUtils.getValueFromLocalStorage('accountType');
        if (rememberURL && accountType === JSON.parse(rememberURL).accountType && currentURL === '/login') {
          history.push(`${JSON.parse(rememberURL).page}`);
        } else if (currentURL !== '/login') {
          history.push(currentURL);
        } else {
          history.push('/dashboard');
        }
      })
      .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

const loginUserThunkAC =
  (payload: { Username: string; Password: string }) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(loginStartAC());
      const res = await SERVICE_API.AuthAPI.postLogin(payload);
      LocalStorageUtils.putValueToLocalStorage('jwt', res?.data.Token);
      LocalStorageUtils.putValueToLocalStorage('exp', (Date.now() + res?.data.ExpiresIn * 1000).toString());
      LocalStorageUtils.putValueToLocalStorage('accountType', res?.data.AccountType);
      LocalStorageUtils.putValueToLocalStorage('user', JSON.stringify(res?.data));
      dispatch(loginUserAC(res?.data));
      window.location.replace('/dashboard');
      dispatch(loginSuccessAC());
    } catch ({ response }: any) {
      dispatch(loginResetStateAC());
      Errors.throwErrorMessageNew(response?.data);
    }
  };

const logoutUserAsync = (api: string, history: History, errorAuth?: boolean, skipRedirect?: boolean) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/auth/logout`, headersBuilder(Method.POST))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .then(() => {
        dispatch(logoutUserAC());
        LocalStorageUtils.removeValueFromLocalStorage('closedStorePopupModal');
        LocalStorageUtils.removeValueFromLocalStorage('feedbackPopupStatus');
        LocalStorageUtils.removeValueFromLocalStorage('accountType');
        LocalStorageUtils.removeValueFromLocalStorage('user');
        LocalStorageUtils.removeValueFromLocalStorage('investorToAttach');
        LocalStorageUtils.removeValueFromLocalStorage('linkedIdMobile');
        LocalStorageUtils.removeValueFromLocalStorage('masters');
        LocalStorageUtils.removeValueFromLocalStorage('investors');
        LocalStorageUtils.putValueToLocalStorage('isAuthenticated', 'false');

        if (!errorAuth) {
          LocalStorageUtils.removeValueFromLocalStorage('rememberPage');
        }

        if (!skipRedirect) {
          history.push('/login');
        }

        window.location.reload();
      })
      .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

export { loginUserThunkAC, logoutUserAsync, loginUserAsync, loginResetStateAC, loginUserSSOAsync };

export default loginUserAsync;
