import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import setLoadingStatus from '../setLoadingStatus';
import headersBuilder, { Method } from '../../../utils/request';
import { PaginationInfo } from '../../../types/sharedTypes';
import { areAllPropsEQFalse, convertAllPropsToBool } from '../../../utils/object';

export const FETCH_REQUESTS = 'FETCH_REQUESTS';

export type FetchRequestsAction = {
  type: string;
  requests: Array<any>;
  paginationInfo: PaginationInfo;
};

export const fetchRequests = (requests: Array<any>, paginationInfo: PaginationInfo): FetchRequestsAction => {
  return {
    type: FETCH_REQUESTS,
    requests,
    paginationInfo
  };
};

function fetchRequestsAsync(
  api: string,
  createdFrom: Date,
  createdTo: Date,
  page?: number,
  limit?: number,
  typeFilters?: any,
  statusFilters?: any,
  idSearch?: string,
  mmChangeFeeAccountsRequestOff?: boolean,
  accountId?: string
) {
  let verifiedTypeFilters: Record<string, boolean>;
  let verifiedStatusFilters: Record<string, boolean>;

  const queryParams = new URLSearchParams([
    ['page', `${page}`],
    ['limit', `${limit}`],
    ['createdFrom', `${Math.round(createdFrom.getTime() / 1000)}`],
    ['createdTo', `${Math.round(createdTo.getTime() / 1000)}`]
  ]);

  if (areAllPropsEQFalse(typeFilters)) {
    verifiedTypeFilters = convertAllPropsToBool(typeFilters, true);
  } else {
    verifiedTypeFilters = typeFilters;
  }

  Object.keys(verifiedTypeFilters).forEach((filter) => {
    if (verifiedTypeFilters[filter]) {
      queryParams.append('typeFilter', filter.toLowerCase());
    }
  });

  if (areAllPropsEQFalse(statusFilters)) {
    verifiedStatusFilters = convertAllPropsToBool(statusFilters, true);
  } else {
    verifiedStatusFilters = statusFilters;
  }

  Object.keys(verifiedStatusFilters).forEach((filter) => {
    if (verifiedStatusFilters[filter]) {
      queryParams.append('statusFilter', filter.toLowerCase());
    }
  });

  if (idSearch) queryParams.append('query', idSearch);
  if (accountId) queryParams.append('accountId', accountId);

  if (mmChangeFeeAccountsRequestOff) {
    queryParams.delete('typeFilter', 'changefeeaccountsinfo');
  }

  return (dispatch: Dispatch) => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/requests?${queryParams.toString()}&sortField=id&direction=desc`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((data: any) => dispatch(fetchRequests(data.Data, data.PaginationInfo)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
}

export default fetchRequestsAsync;
