import React from 'react';

const ToastErrorIcon: React.FC = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="41" viewBox="0 0 45 41" fill="none">
      <path
        d="M45 37.72C45 38.786 44.6294 39.5992 43.8881 40.1595C43.1469 40.7198 42.2372 41 41.159 41H3.84097C2.7628 41 1.8531 40.713 1.11186 40.139C0.37062 39.565 0 38.7587 0 37.72C0 36.9 0.242588 36.0663 0.727763 35.219L19.3666 2.255C20.2561 0.751667 21.3073 0 22.5202 0C23.7332 0 24.7574 0.751667 25.593 2.255L44.2722 35.26C44.7574 36.1347 45 36.9547 45 37.72ZM25.31 16.769V10.783H19.69V16.769C19.69 17.1517 19.717 17.5138 19.7709 17.8555C19.8248 18.1972 19.8989 18.5798 19.9933 19.0035C20.0876 19.4272 20.1617 19.7893 20.2156 20.09L21.2668 26.732H23.6523L24.7439 20.09C24.7978 19.8167 24.8787 19.4613 24.9865 19.024C25.0943 18.5867 25.1752 18.1972 25.2291 17.8555C25.283 17.5138 25.31 17.1517 25.31 16.769ZM25.31 32.636C25.31 31.8433 25.0337 31.1737 24.4811 30.627C23.9286 30.0803 23.2615 29.807 22.4798 29.807C21.7251 29.807 21.0714 30.0803 20.5189 30.627C19.9663 31.1737 19.69 31.8433 19.69 32.636C19.69 33.4287 19.9663 34.1052 20.5189 34.6655C21.0714 35.2258 21.7251 35.506 22.4798 35.506C23.2615 35.506 23.9286 35.2258 24.4811 34.6655C25.0337 34.1052 25.31 33.4287 25.31 32.636Z"
        fill="#C36167"
      />
    </svg>
  );
};

export default ToastErrorIcon;
