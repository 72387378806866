import React from 'react';

interface ArrowProps {
  isOpen?: boolean;
  fill: string;
}

const ArrowUpDownIcon: React.FC<ArrowProps> = ({ isOpen, fill }): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      style={{
        transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
        transition: 'transform 0.3s ease'
      }}
    >
      <path
        d="M25.996 10.652L16 20.396L6.00403 10.652C5.82543 10.4776 5.58568 10.3799 5.33603 10.3799C5.08638 10.3799 4.84663 10.4776 4.66803 10.652C4.58155 10.7368 4.51285 10.8379 4.46595 10.9495C4.41905 11.0611 4.3949 11.181 4.3949 11.302C4.3949 11.4231 4.41905 11.5429 4.46595 11.6546C4.51285 11.7662 4.58155 11.8673 4.66803 11.952L15.302 22.32C15.4888 22.5021 15.7392 22.6039 16 22.6039C16.2608 22.6039 16.5113 22.5021 16.698 22.32L27.332 11.954C27.4191 11.8692 27.4883 11.7679 27.5356 11.6559C27.5829 11.5439 27.6072 11.4236 27.6072 11.302C27.6072 11.1805 27.5829 11.0602 27.5356 10.9482C27.4883 10.8362 27.4191 10.7348 27.332 10.65C27.1534 10.4756 26.9137 10.3779 26.664 10.3779C26.4144 10.3779 26.1746 10.4756 25.996 10.65V10.652Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowUpDownIcon;
