import {
  FETCH_MASTERS,
  FetchMastersAction,
  FETCH_MASTERS_DEFAULT,
  FetchMastersDefaultAction,
  UPDATE_MASTERS_CURRENT_PAGE_INFO,
  UpdateMastersCurrentPage
} from '../../actions/master/fetchMasters';
import { Master } from '../../../types/masterTypes';
import { PaginationInfo } from '../../../types/sharedTypes';

export interface MastersState {
  masters: Array<Master>;
  mastersDefault: Array<Master>;
  paginationInfo: PaginationInfo;
  currentPageInfo: { limit: number; currentPage: number };
}

const initialState: MastersState = {
  masters: [],
  mastersDefault: [],
  paginationInfo: new PaginationInfo(),
  currentPageInfo: { limit: 10, currentPage: 1 }
};

type FetchMasters = FetchMastersAction & FetchMastersDefaultAction & UpdateMastersCurrentPage;

const mastersReducer = (state: MastersState = initialState, action: FetchMasters): MastersState => {
  switch (action.type) {
    case FETCH_MASTERS:
      return {
        ...state,
        masters: action.masters,
        paginationInfo: action.paginationInfo
      };
    case FETCH_MASTERS_DEFAULT:
      return {
        ...state,
        mastersDefault: action.mastersDefault
      };
    case UPDATE_MASTERS_CURRENT_PAGE_INFO:
      return {
        ...state,
        currentPageInfo: action.currentPageInfo
      };
    default:
      return state;
  }
};

export default mastersReducer;
