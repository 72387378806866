import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { History } from 'history';
import Errors from '../../../shared/validation/errors';
import LocalStorageUtils from '../../../utils/local-storage';
import fetchInvestorDetailsAsync from './fetchInvestorDetails';
import { getJwt } from '../auth';
import fetchRequestsCountAsync from '../requests/fetchRequestsCount';

const detachInvestor = (api: string, investorId: number) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/investors/detach/${investorId}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => Errors.checkResponseForErrors(response, api))
      // eslint-disable-next-line no-alert
      .then(() => {
        toast.success('Investor detached successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch(fetchInvestorDetailsAsync(api, Number(investorId)) as any);
      })
      .catch((error) => Errors.throwErrorMessage(error));
  };
};

export const createRequestToDetachInvestor = (
  api: string,
  investorId: string,
  masterId: string,
  history: History,
  isMobile?: boolean
) => {
  return (dispatch: any): void => {
    fetch(`${api}/requests/create/detachInvestor`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ AccountId: parseInt(investorId, 10) }) // TODO MmId: parseInt(masterId, 10),
    })
      .then((response) => Errors.checkResponseForErrors(response, api))
      // eslint-disable-next-line no-alert
      .then(() => {
        LocalStorageUtils.putValueToLocalStorage('masterIdDetachRequestSent', masterId);
        toast.success('Request created successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch(fetchRequestsCountAsync(api));
        history.push('/dashboard');
      })
      .catch((error) => Errors.throwErrorMessage(error));
  };
};

export default detachInvestor;
