import React from 'react';

const MoneyManagersIcon: React.FC = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17.5C10 16.3954 10.8954 15.5 12 15.5H21C22.1046 15.5 23 16.3954 23 17.5V21.5C23 22.6046 22.1046 23.5 21 23.5H12C10.8954 23.5 10 22.6046 10 21.5V17.5ZM18 19.5C18 20.6046 17.3284 21.5 16.5 21.5C15.6716 21.5 15 20.6046 15 19.5C15 18.3954 15.6716 17.5 16.5 17.5C17.3284 17.5 18 18.3954 18 19.5ZM13.5 16.1738L12.5 16.1738V16.9999C12.5 17.3688 12.314 17.5971 12.0124 17.76C11.6892 17.9345 11.2773 17.9999 11 17.9999V18.9999C11.3894 18.9999 11.9775 18.9154 12.4876 18.6399C13.0194 18.3527 13.5 17.8311 13.5 16.9999V16.1738ZM13.5 21.7897V22.4999L12.5 22.5V21.7897C12.5 21.5456 12.3624 21.3614 12.0394 21.2114C11.7088 21.0579 11.2865 21 11 21V20C11.3802 20 11.9579 20.0711 12.4606 20.3044C12.971 20.5414 13.5 21.0021 13.5 21.7897ZM19.5 17.2103C19.5 17.9979 20.029 18.4586 20.5394 18.6956C21.0421 18.9289 21.6198 19 22 19V18C21.7135 18 21.2912 17.9421 20.9606 17.7886C20.6376 17.6386 20.5 17.4544 20.5 17.2103V16.5001L19.5 16.5V17.2103ZM19.5 21.7897V22.4999L20.5 22.5V21.7897C20.5 21.5456 20.6376 21.3614 20.9606 21.2114C21.2912 21.0579 21.7135 21 22 21V20C21.6198 20 21.0421 20.0711 20.5394 20.3044C20.029 20.5414 19.5 21.0021 19.5 21.7897Z"
        fill="currentColor"
      />
      <path
        d="M12 6.5C12 8.70914 10.2091 10.5 8 10.5C5.79086 10.5 4 8.70914 4 6.5C4 4.29086 5.79086 2.5 8 2.5C10.2091 2.5 12 4.29086 12 6.5Z"
        fill="currentColor"
      />
      <path
        d="M9 18.5V16.5C9 15.3955 9.89545 14.5 11 14.5H14.6557C13.3004 12.6865 11.0486 11.5 8.5 11.5C4.35785 11.5 1 14.634 1 18.5H9Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MoneyManagersIcon;
