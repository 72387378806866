import { useLayoutEffect, useState } from 'react';
import { mobileWidth } from '../const/size.const';

const useShowMobileVersion = () => {
  const [show, setShow] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setShow(window.innerWidth < mobileWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return show;
};

export default useShowMobileVersion;
