import { VisibilityToggle, StateToggle } from './toggles';
import { IncentiveAccount, OrderMode, Summary } from './sharedTypes';

export enum PeriodTypes {
  None = 'None',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly'
}

export enum CommissionType {
  Performance = 'Performance',
  AnnualManagement = 'AnnualManagement'
}

export enum MasterMode {
  Normal = 0,
  Disabled = 1,
  Test = 2
}

export enum AllocationModes {
  ByBalance = 0,
  ByEquity = 1
}

export class RegularPeriod {
  [index: string]: any;

  PeriodType = PeriodTypes.Monthly;
  PeriodDay: number | string = 1;
  PeriodTime = '';
  ReversedDays = false;
  Valid? = true;
}

export class PaymentPeriod extends RegularPeriod {
  CommissionType = CommissionType.Performance;
}

export interface IPaymentPeriod {
  CommissionType: CommissionType;
  PeriodType: PeriodTypes;
  PeriodDay: number | string;
  PeriodTime: string;
  ReversedDays: boolean;
  Valid: boolean;
}

export class AccountOptionsMaster {
  CalcMasterFeeButton = VisibilityToggle.Default;
  MmConnectedInvestors = VisibilityToggle.Default;
  OrdersCommissions = VisibilityToggle.Default;
  ShowMmOwnFunds = VisibilityToggle.Default;
  EmailNotifications = StateToggle.Default;
  LockedWithdrawalsAndDisconnections = StateToggle.Default;
  MmChangeFeeAccountsRequest = StateToggle.Default;
  ConnectDisconnectRequestsManaging = StateToggle.Default;
}

export class OwnFunds {
  Id = 0;
  Balance = 0;
  Credit = 0;
  Equity = 0;
  EquityNoCredit = 0;
  Profit = 0;
  FreeMargin = 0;
  FreeMarginNoCredit = 0;
  AvailableFreeMargin = 0;
  AvailableFreeMarginNoCredit = 0;
  Investments = 0;
}

export class Master {
  Login = 0;
  ServerName = '';
  MtServerType = '';
  Id = 0;
  Name = '';
  Username = '';
  Password = '';
  Email = '';
  Currency = 'USD';
  RegistrationDate = '';
  Private = false;
  Invisible = false;
  InvestorsCount = 0;
  AllocationMode = 0;
  MinInvestment = 0;
  IncentiveAccounts: Array<IncentiveAccount> = [new IncentiveAccount()];
  Balance = 0;
  Credit = 0;
  Equity = 0;
  EquityNoCredit = 0;
  Profit = 0;
  Margin = 0;
  FreeMargin = 0;
  FreeMarginNoCredit = 0;
  MarginLevel = 0;
  Mode = MasterMode.Normal;
  DisablingTime = null;
  TotalProfit = 0;
  Info: string | null = '';
  Bio = '';
  TermsAndConditions = '';
  PaymentPeriods: Array<IPaymentPeriod> = [];
  StatementPeriods: Array<RegularPeriod> = [new RegularPeriod()];
  UseDefaultStatementSettings = false;
  AccountOptions = new AccountOptionsMaster();
  OwnFunds = new OwnFunds();
  MtServerStatus = 0;
  MtServerStatusStr = '';
  So = '';
  SoMode = OrderMode.Off;
  MtMmAccountStatus = '';
  IsOwnFunds = true;
  TotalPayments = 0;
  TotalTradeProfit = 0;
  ReferralLink = '';
}

export class CreateMaster {
  LoadHistoryMonthCount? = 0;
  Server = '';
  Login? = 0;
  IncentiveAccounts?: Array<IncentiveAccount> = [new IncentiveAccount()];
  PaymentPeriods?: Array<PaymentPeriod> = [new PaymentPeriod()];
  StatementPeriods?: Array<RegularPeriod> = [new RegularPeriod()];
  // AccountOptions = new AccountOptionsMaster();
  UseDefaultPaymentSettings = true;
  UseDefaultStatementSettings = true;
  AllocationMode = AllocationModes.ByBalance;
  MinInvestment = '';
  Name = '';
  Username = '';
  Password = '';
  Email = '';
  Leverage? = 1;
  MtGroup? = '';
  CreateMtAccount? = false;
}

export class UpdateMaster {
  Username? = '';
  Password? = '';
  Name? = '';
  Email? = '';
  AllocationMode? = 0;
  Mode? = MasterMode.Normal;
  MinInvestment? = 0;
  Private? = false;
  Invisible? = false;
  Bio? = '';
  TermsAndConditions? = '';
  So? = 0;
  SoMode? = '';
}

export class Order {
  MtOrderId = 0;
  Id = 0;
  MtServer = '';
  MmId = 0;
  MmLogin = 0;
  Volume = 0;
  Type = '';
  Symbol = '';
  OpenTime = '';
  OpenPrice = '';
  CloseTime = '';
  ClosePrice = '';
  Commission = 0;
  Tax = 0;
  Swap = 0;
  Profit = 0;
  Comment = 0;
}

export enum FundsType {
  TotalFunds = 'TotalFunds',
  // eslint-disable-next-line no-shadow
  OwnFunds = 'OwnFunds'
}

export class RegisterMaster {
  AllocationMode = 0;
  MinInvestment = 0;
  Name = '';
  Username = '';
  Email = '';
  IncentiveAccounts = [new IncentiveAccount()];
  Server = '';
  Login = 0;
  Currency = 'USD';
  Comment = '';
}

export interface InvestorOrder {
  MasterId: number;
  CloseTime: string;
  Comment: string;
  MasterOrderId: string;
  InvestorId: number;
  Id: string;
  MtOrderId: number;
  Volume: number;
  Symbol: string;
  Direction: number;
  OpenTime: string;
  OpenPrice: number;
  ClosePrice: number;
  Profit: number;
  Commission: number;
  Tax: number;
  Swap: number;
  RemainProfit?: number | any;
}

export interface MasterProfit {
  masterId: number;
  profitFortnight: Array<Summary>;
}

export enum IDefaultSorting {
  login = 'login',
  id = 'id',
  popularity = 'popularity',
  profit = 'profit',
  incentive = 'incentive',
  mininvestment = 'mininvestment',
  name = 'name'
}
