import { FETCH_ADMIN_DETAILS, FetchAdminDetailsAction } from '../../actions/admin/fetchAdminDetails';
import { EDIT_ADMIN, EditAdminAction } from '../../actions/admin/editAdmin';
import { Admin } from '../../../types/adminTypes';

export interface AdminDetailsState {
  admin: Admin;
}

const initialState: AdminDetailsState = {
  admin: new Admin()
};

type AdminAction = FetchAdminDetailsAction & EditAdminAction;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const adminReducer = (state: AdminDetailsState = initialState, action: AdminAction): AdminDetailsState => {
  switch (action.type) {
    case FETCH_ADMIN_DETAILS:
      return {
        ...state,
        admin: action.adminDetails
      };
    case EDIT_ADMIN:
      return {
        ...state,
        admin: action.editedAdmin
      };
    default:
      return state;
  }
};

export default adminReducer;
