/* eslint-disable react/require-default-props */
import React, { Suspense } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { History } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { PrivateRoute, PrivateRouteNoFetch } from '../privateRoute';
import { AccountType } from '../../types/authTypes';
import LocalStorageUtils from '../../utils/local-storage';
import { PAMMState } from '../../redux/reducers/rootReducer';
import { Investor } from '../../types/investorTypes';
import SoMobile from '../mobile/SoMobile';
import useShowMobileVersion from '../../hooks/useShowMobileVersion';
import { logoutUserAsync, removeJwt } from '../../redux/actions/auth';

// Desktop components
const Login = React.lazy(() => import('../auth/login/Login'));
const InvestorsList = React.lazy(() => import('../administrator/investor/InvestorsList'));
const InvestorDetails = React.lazy(() => import('../administrator/investor/InvestorDetails'));
const MastersList = React.lazy(() => import('../administrator/master/MastersList'));
const MasterDetails = React.lazy(() => import('../administrator/master/MasterDetails'));
const AdminDetails = React.lazy(() => import('../administrator/admin/AdminDetails'));
const Admins = React.lazy(() => import('../administrator/admin/AdminsList'));
const Settings = React.lazy(() => import('../administrator/settings/Settings'));
const CreateUser = React.lazy(() => import('../CreateUser'));
const Journal = React.lazy(() => import('../administrator/journal/Journal'));
const AdminDashboard = React.lazy(() => import('../administrator/admin/AdminDashboard'));
const RequestsList = React.lazy(() => import('../administrator/requests/RequestsList'));
const FeeAccountsList = React.lazy(() => import('../administrator/feeAccounts/FeeAccountsList'));
const OrdersList = React.lazy(() => import('../administrator/orders/OrdersList'));
const ResetPassword = React.lazy(() => import('../resetPassword/ResetPassword'));
const RegisterUserAuth = React.lazy(() => import('../auth/register/RegisterUserAuth'));
const RegisterUserSelect = React.lazy(() => import('../auth/register/RegisterUserSelect'));
const MaintenancePage = React.lazy(() => import('../administrator/maintenance/MaintenancePage'));
const Monitoring = React.lazy(() => import('../administrator/monitoring/MonitoringDetails'));
const MassAttachDetach = React.lazy(() => import('../administrator/massAttachDettach/MassAttachDetach'));
const InvestorsReport = React.lazy(() => import('../administrator/investorsReport/InvestorsReport'));

// Mobile components
const LoginMobile = React.lazy(() => import('../mobile/LoginMobile'));
const MasterDetailsMobile = React.lazy(() => import('../mobile/MasterDetailsMobile'));
const MastersListMobile = React.lazy(() => import('../mobile/MastersListMobile'));
const RequestsListMobile = React.lazy(() => import('../administrator/requests/mobile/RequestsListMobile'));
const RequestsFiltersMobile = React.lazy(() => import('../administrator/requests/mobile/RequestsFiltersMobile'));
const InvestorDetailsMobile = React.lazy(() => import('../mobile/InvestorDetailsMobile'));
const ProfileMobile = React.lazy(() => import('../mobile/ProfileMobile'));
const ChangeBalanceCreditMobile = React.lazy(() => import('../mobile/ChangeBalanceCreditMobile'));
const CommissionsMobile = React.lazy(() => import('../mobile/CommissionsMobile'));
const DownloadStatementMobile = React.lazy(() => import('../mobile/DownloadStatementMobile'));
const HistoryMobile = React.lazy(() => import('../mobile/HistoryMobile'));
const OrderMobile = React.lazy(() => import('../mobile/OrderMobile'));
const BalanceOperationMobile = React.lazy(() => import('../mobile/BalanceOperationMobile'));
const BioMobile = React.lazy(() => import('../mobile/BioMobile'));
const RefLinkMobile = React.lazy(() => import('../mobile/RefLinkMobile'));
const SlTpMobile = React.lazy(() => import('../mobile/SlTpMobile'));
const RegisterUserMobile = React.lazy(() => import('../mobile/RegisterUserMobile'));
const RegisterUserSelectMobile = React.lazy(() => import('../mobile/RegisterUserSelectMobile'));
const ConnectedInvestorsMobile = React.lazy(() => import('../mobile/ConnectedInvestors'));
const ConnectedInvestorDetailMobile = React.lazy(() => import('../mobile/ConnectedInvestorDetail'));

// New interface components
const InvestorNewRequestsList = React.lazy(() => import('../generalUser/requestsNew/RequestsListNewInvestor'));

const MasterNewRequestsList = React.lazy(() => import('../generalUser/requestsNew/RequestsListNewMaster'));

const LinkedAccountsMainPage = React.lazy(() => import('../generalUser/investor/LinkedAccountsMainPage'));
const WalletsPage = React.lazy(() => import('../generalUser/WalletsPage'));
const InvestorDashboard = React.lazy(() => import('../generalUser/investor/InvestorDashboard'));
const MasterOwnFundsDashboard = React.lazy(() => import('../generalUser/master/MasterOwnFundsDashboard'));
const MasterDashboard = React.lazy(() => import('../generalUser/master/MasterDashboard'));
const InvestorEditProfile = React.lazy(() => import('../generalUser/investor/InvestorEditProfile'));
const MasterEditProfile = React.lazy(() => import('../generalUser/master/MasterEditProfile'));
const IframePage = React.lazy(() => import('../iframePage/IframePage'));

const GenUserMastersListNew = React.lazy(() => import('../generalUser/investor/MasterList/MastersListNew'));

interface IAppRoutesProps {
  isAuthenticated: boolean;
  currentLang?: string;
}

const AppRoutes: React.FC<IAppRoutesProps> = ({ isAuthenticated, currentLang }: IAppRoutesProps): JSX.Element => {
  const showMobileVersion = useShowMobileVersion();

  const history: History = useHistory();
  const userJSON = LocalStorageUtils.getValueFromLocalStorage('user');
  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);

  const dispatch = useDispatch();
  const api = useSelector((state: PAMMState) => state.configReducer.api);

  let user: any;

  if (typeof userJSON === 'string') {
    user = JSON.parse(userJSON);
  }

  React.useEffect(() => {
    return history.listen((location) => {
      if (!location.pathname.includes('money_managers')) {
        LocalStorageUtils.removeValueFromLocalStorage('investorToAttach');
      }
    });
  }, [history]);

  const fillRoutes = (): Array<JSX.Element> => {
    const routes: Array<JSX.Element> = [];
    let redirectAfterLogin: JSX.Element;

    if (user) {
      switch (user.AccountType) {
        case AccountType.Admin:
          redirectAfterLogin = <Redirect to="/dashboard" />;
          break;
        case AccountType.Investor:
          redirectAfterLogin = <Redirect to="/dashboard" />;
          break;
        case AccountType.Master:
          redirectAfterLogin = <Redirect to="/dashboard" />;
          break;
        default:
          break;
      }
    }

    const loginPage = () =>
      isAuthenticated ? (
        redirectAfterLogin
      ) : (
        <div className="text-center">
          {/* {showMobileVersion ? <LoginMobile /> : <AppLoginPage signInFunc={signInFunc} />} */}
          {showMobileVersion ? <LoginMobile /> : <Login />}
        </div>
      );

    const refCodePage = ({ match }: any) => {
      const { refCode } = match.params;
      if (isAuthenticated) {
        dispatch(logoutUserAsync(api, history, false, true));
        removeJwt();
      }
      return <Redirect to={`/register-user/investor/ref/${refCode}`} />;
    };

    if (user) {
      // ADMINISTRATOR (OLD INTERFACE (desktop only))
      if (user.AccountType === AccountType.Admin) {
        routes.push(
          <PrivateRoute
            key="investors-details"
            path="/investors/:id/:graphic/:table"
            isAuthenticated={isAuthenticated}
            component={<InvestorDetails />}
          />
        );
        routes.push(
          <PrivateRoute
            key="money-manager-details"
            path="/money_managers/:id/:graphic/:table"
            isAuthenticated={isAuthenticated}
            component={<MasterDetails history={history} />}
          />
        );
        routes.push(
          <PrivateRoute
            key="money-manager-details"
            path="/money_managers/attach/:id"
            isAuthenticated={isAuthenticated}
            component={<MastersList history={history} />}
          />
        );
        routes.push(
          <PrivateRoute
            key="investors-report"
            path="/investorsReport"
            isAuthenticated={isAuthenticated}
            component={<InvestorsReport />}
          />
        );
        routes.push(
          <PrivateRoute
            key="master-create"
            path="/settings/:type/:subtype?"
            isAuthenticated={isAuthenticated}
            component={<Settings currentLang={currentLang} />}
          />
        );
        routes.push(
          <PrivateRoute
            key="create-user"
            path="/create/:user"
            isAuthenticated={isAuthenticated}
            component={<CreateUser history={history} />}
          />
        );
        routes.push(
          <PrivateRoute key="edit-admin" path="/admins/:id" isAuthenticated={isAuthenticated} component={<AdminDetails />} />
        );
        routes.push(
          <PrivateRoute key="investors" path="/investors" isAuthenticated={isAuthenticated} component={<InvestorsList />} />
        );
        routes.push(
          <PrivateRoute
            key="maintenance"
            path="/maintenance:type?"
            isAuthenticated={isAuthenticated}
            component={<MaintenancePage history={history} />}
          />
        );
        routes.push(
          <PrivateRoute key="monitoring" path="/monitoring" isAuthenticated={isAuthenticated} component={<Monitoring />} />
        );
        routes.push(
          <PrivateRoute
            key="investors-action"
            path="/:action/investors"
            isAuthenticated={isAuthenticated}
            component={<MassAttachDetach history={history} />}
          />
        );
        routes.push(
          <PrivateRoute
            key="money_managers"
            path="/money_managers"
            isAuthenticated={isAuthenticated}
            component={<MastersList />}
          />
        );
        routes.push(<PrivateRoute key="admins" path="/admins" isAuthenticated={isAuthenticated} component={<Admins />} />);
        routes.push(<PrivateRoute key="journal" path="/journal" isAuthenticated={isAuthenticated} component={<Journal />} />);
        routes.push(
          <PrivateRoute key="dashboard" path="/dashboard" isAuthenticated={isAuthenticated} component={<AdminDashboard />} />
        );
        routes.push(
          <PrivateRoute key="requests" path="/requests" isAuthenticated={isAuthenticated} component={<RequestsList />} />
        );
        routes.push(
          <PrivateRoute
            key="fee-accounts"
            path="/fee-accounts"
            isAuthenticated={isAuthenticated}
            component={<FeeAccountsList />}
          />
        );
        routes.push(<PrivateRoute key="orders" path="/orders" isAuthenticated={isAuthenticated} component={<OrdersList />} />);
      }

      // INVESTOR (NEW DESKTOP INTERFACE)
      if (user.AccountType === AccountType.Investor && !showMobileVersion) {
        routes.push(
          <PrivateRouteNoFetch
            key="investors-dashboard-details"
            path="/dashboard/:id"
            isAuthenticated={isAuthenticated}
            component={<InvestorDashboard />}
          />
        );
        routes.push(
          <PrivateRouteNoFetch
            key="investors-dashboard"
            path="/dashboard"
            isAuthenticated={isAuthenticated}
            component={<LinkedAccountsMainPage />}
          />
        );
        routes.push(
          <PrivateRouteNoFetch
            key="money-manager-details"
            path="/money_managers/:id"
            isAuthenticated={isAuthenticated}
            component={<MasterDashboard />}
          />
        );
        routes.push(
          <PrivateRouteNoFetch
            key="money_managers"
            path="/money_managers"
            isAuthenticated={isAuthenticated}
            component={<GenUserMastersListNew />}
          />
        );
        routes.push(
          <PrivateRouteNoFetch
            key="requests"
            path="/requests"
            isAuthenticated={isAuthenticated}
            component={<InvestorNewRequestsList />}
          />
        );
        routes.push(
          <PrivateRouteNoFetch key="wallets" path="/wallets" isAuthenticated={isAuthenticated} component={<WalletsPage />} />
        );
        routes.push(
          <PrivateRouteNoFetch
            key="editProfile"
            path="/editProfile/:id"
            isAuthenticated={isAuthenticated}
            component={<InvestorEditProfile />}
          />
        );
      }
      // MASTER (NEW DESKTOP INTERFACE)
      if (user.AccountType === AccountType.Master && !showMobileVersion) {
        routes.push(
          <PrivateRoute
            key="masters-dashboard"
            path="/dashboard"
            isAuthenticated={isAuthenticated}
            component={<MasterDashboard />}
          />
        );
        routes.push(
          <PrivateRoute
            key="investor-details"
            path="/investors/:id"
            isAuthenticated={isAuthenticated}
            component={<InvestorDashboard />}
          />
        );
        routes.push(
          <PrivateRoute
            key="money_managers"
            path="/money_managers"
            isAuthenticated={isAuthenticated}
            component={<GenUserMastersListNew />}
          />
        );
        routes.push(
          <PrivateRoute key="requests" path="/requests" isAuthenticated={isAuthenticated} component={<MasterNewRequestsList />} />
        );
        routes.push(
          <PrivateRoute
            key="ownFunds"
            path="/ownFunds"
            isAuthenticated={isAuthenticated}
            component={<MasterOwnFundsDashboard />}
          />
        );
        routes.push(<PrivateRoute key="wallets" path="/wallets" isAuthenticated={isAuthenticated} component={<WalletsPage />} />);
        routes.push(
          <PrivateRoute
            key="editProfile"
            path="/editProfile/:id"
            isAuthenticated={isAuthenticated}
            component={<MasterEditProfile />}
          />
        );
      }

      // INVESTOR or MASTER (MOBILE/TABLET INTERFACE))
      if (showMobileVersion) {
        if (user.AccountType === AccountType.Investor || user.AccountType === AccountType.Master) {
          routes.push(
            <PrivateRoute
              key="order"
              path="/closed-order/:id/:closedOrderId"
              isAuthenticated={isAuthenticated}
              component={<OrderMobile user={user} />}
            />
          );
          routes.push(
            <PrivateRoute key="sltp" path="/edit-sltp/:id/" isAuthenticated={isAuthenticated} component={<SlTpMobile />} />
          );
          routes.push(<PrivateRoute key="soMobile" path="/so/:id/" isAuthenticated={isAuthenticated} component={<SoMobile />} />);
          routes.push(
            <PrivateRoute
              key="change-balance"
              path="/change-balance/:id"
              isAuthenticated={isAuthenticated}
              component={<ChangeBalanceCreditMobile />}
            />
          );
          routes.push(
            <PrivateRoute
              key="change-credit"
              path="/change-credit/:id"
              isAuthenticated={isAuthenticated}
              component={<ChangeBalanceCreditMobile />}
            />
          );
          routes.push(
            <PrivateRoute
              key="download-statement"
              path="/download-statement/:id"
              isAuthenticated={isAuthenticated}
              component={<DownloadStatementMobile />}
            />
          );
          routes.push(
            <PrivateRoute
              key="commissions"
              path="/commissions/:id"
              isAuthenticated={isAuthenticated}
              component={<CommissionsMobile />}
            />
          );
          routes.push(
            <PrivateRoute
              key="connected-investors"
              path="/connected-investors/:id"
              isAuthenticated={isAuthenticated}
              component={<ConnectedInvestorsMobile />}
            />
          );
          routes.push(
            <PrivateRoute
              key="connected-investors"
              path="/investors/:investorId"
              isAuthenticated={isAuthenticated}
              component={<ConnectedInvestorDetailMobile />}
            />
          );

          if (user.AccountType === AccountType.Investor && investor.AccountOptions.InvestorMmDetails === 'Show') {
            routes.push(
              <PrivateRoute
                key="money-manager-details"
                path="/money_managers/:id"
                isAuthenticated={isAuthenticated}
                component={<MasterDetailsMobile />}
              />
            );
          }

          routes.push(
            <PrivateRoute key="history" path="/history/:id" isAuthenticated={isAuthenticated} component={<HistoryMobile />} />
          );
          routes.push(<PrivateRoute key="bio" path="/bio/:id" isAuthenticated={isAuthenticated} component={<BioMobile />} />);
          routes.push(
            <PrivateRoute
              key="referral-link"
              path="/referral-link"
              isAuthenticated={isAuthenticated}
              component={<RefLinkMobile />}
            />
          );
          routes.push(
            <PrivateRoute
              key="balance-operation"
              path="/balance-operation/:id/:balanceOperationId"
              isAuthenticated={isAuthenticated}
              component={<BalanceOperationMobile />}
            />
          );
          routes.push(
            <PrivateRoute
              key="requests-filters"
              path="/requests/filters"
              isAuthenticated={isAuthenticated}
              component={<RequestsFiltersMobile />}
            />
          );
          routes.push(
            <PrivateRoute
              key="money_managers"
              path="/money_managers"
              isAuthenticated={isAuthenticated}
              component={<MastersListMobile />}
            />
          );
          routes.push(
            <PrivateRoute key="requests" path="/requests" isAuthenticated={isAuthenticated} component={<RequestsListMobile />} />
          );
          routes.push(
            <PrivateRoute
              key="dashboard"
              path="/dashboard"
              isAuthenticated={isAuthenticated}
              component={
                user.AccountType === AccountType.Investor ? (
                  <InvestorDetailsMobile investorId={user.Id} />
                ) : (
                  <MasterDetailsMobile masterId={user.Id} />
                )
              }
            />
          );
          routes.push(
            <PrivateRoute
              key="profile"
              path="/profile"
              isAuthenticated={isAuthenticated}
              component={<ProfileMobile user={user} />}
            />
          );
        }
      }
    }

    if (showMobileVersion) {
      routes.push(
        <Route key="register-user" path="/register-user/investor/ref/:refCode" render={() => <RegisterUserMobile isMobile />} />
      );
      routes.push(<Route key="register-user" path="/register-user/:userType/" render={() => <RegisterUserMobile isMobile />} />);
      routes.push(<Route key="register-user" path="/register-user/" render={() => <RegisterUserSelectMobile />} />);
    } else {
      routes.push(<Route key="register-user" path="/register-user/investor/ref/:refCode" render={() => <RegisterUserAuth />} />);
      routes.push(<Route key="register-user" path="/register-user/:userType/" render={() => <RegisterUserAuth />} />);
      routes.push(<Route key="register-user" path="/register-user" render={() => <RegisterUserSelect />} />);
    }
    routes.push(<Route key="reset-password" path="/reset-password" render={() => <ResetPassword />} />);
    routes.push(<Route key="iframe-page" path="/iframe-page" render={() => <IframePage />} />);
    routes.push(<Route key="admin" path="/admin" render={loginPage} />);
    routes.push(<Route key="ref" path="/ref/:refCode" render={refCodePage} />);
    routes.push(<Route key="login" path="/" render={loginPage} />);

    return routes;
  };

  return (
    <Suspense fallback="Loading...">
      <Switch>{fillRoutes()}</Switch>
    </Suspense>
  );
};

export default AppRoutes;
