import {
  SET_OPS_MENU_STATUS,
  SET_LINKED_ACCOUNTS_MENU_STATUS,
  SET_LANGUAGES_MENU_STATUS,
  SET_CONNECT_TO_MASTER_CONFIRMATION_STATUS,
  SET_DISCONNECT_FROM_MASTER_CONFIRMATION_STATUS,
  SetMobileMenusStatus
} from '../actions/setMobileMenusStatus';

export interface MobileMenusStatusState {
  showOpsMenu: boolean;
  showlinkedAccountsMenu: boolean;
  showLanguagesMenu: boolean;
  showConnectToMasterConfirmation: boolean;
  showDisconnectFromMasterConfirmation: boolean;
}

const initialState: MobileMenusStatusState = {
  showOpsMenu: false,
  showlinkedAccountsMenu: false,
  showLanguagesMenu: false,
  showConnectToMasterConfirmation: false,
  showDisconnectFromMasterConfirmation: false
};

function mobileMenusStatusReducer(
  state: MobileMenusStatusState = initialState,
  action: SetMobileMenusStatus
): MobileMenusStatusState {
  switch (action.type) {
    case SET_OPS_MENU_STATUS: {
      return {
        ...state,
        showOpsMenu: action.status
      };
    }
    case SET_LINKED_ACCOUNTS_MENU_STATUS: {
      return {
        ...state,
        showlinkedAccountsMenu: action.status
      };
    }
    case SET_LANGUAGES_MENU_STATUS: {
      return {
        ...state,
        showLanguagesMenu: action.status
      };
    }
    case SET_CONNECT_TO_MASTER_CONFIRMATION_STATUS: {
      return {
        ...state,
        showConnectToMasterConfirmation: action.status
      };
    }
    case SET_DISCONNECT_FROM_MASTER_CONFIRMATION_STATUS: {
      return {
        ...state,
        showDisconnectFromMasterConfirmation: action.status
      };
    }
    default: {
      return state;
    }
  }
}

export default mobileMenusStatusReducer;
