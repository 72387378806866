/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import OperationsMenuMobileCss from './OperationsMenuMobile.module.css';
import Icons from '../../icons/icons';
import {
  setDisconnectFromMasterConfirmationStatus,
  setLanguagesMenuStatus,
  setOpsMenuStatus
} from '../../redux/actions/setMobileMenusStatus';
import { PAMMState } from '../../redux/reducers/rootReducer';
import { AccountType } from '../../types/authTypes';
import LocalStorageUtils from '../../utils/local-storage';
import { Investor } from '../../types/investorTypes';

const OperationsMenuMobile: React.FC<any> = ({ menuHeight = 0, onSwipe, onCancel, location, currentLang }): JSX.Element => {
  const dispatch = useDispatch();

  const languages: Record<string, string> = useSelector((state: PAMMState) => state.configReducer.languages);
  const customColorsGenUser = useSelector((state: PAMMState) => state.configReducer.customColorsGeneralUser);
  const userJSON: string | null = LocalStorageUtils.getValueFromLocalStorage('user');
  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);
  const customColors: Record<string, string> = useSelector((state: PAMMState) => state.configReducer.customColorsGeneralUser);

  let user: any;

  if (typeof userJSON === 'string') {
    user = JSON.parse(userJSON);
  }

  const linkedId = LocalStorageUtils.getValueFromLocalStorage('linkedIdMobile') || user?.Id;

  const showDisconnectFromThisMasterWarning = investor.MasterId !== 0 && investor?.AccountOptions?.DetachAction !== 'Off';

  React.useEffect(() => {
    dispatch(setOpsMenuStatus(false));
  }, [dispatch, location]);

  const handleShowLanguagesMenu = (): void => {
    dispatch(setOpsMenuStatus(false));
    dispatch(setLanguagesMenuStatus(true));
  };

  const handleDetachInvestor = (): void => {
    dispatch(setDisconnectFromMasterConfirmationStatus(true));
    dispatch(setOpsMenuStatus(false));
  };

  const style = {
    width: '100%',
    height: menuHeight,
    position: 'fixed',
    zIndex: 9999,
    bottom: '-5rem',
    backgroundColor: 'white',
    transition: '0.5s',
    padding: '1.5rem',
    fontSize: '1.3rem',
    paddingTop: '32px'
  } as React.CSSProperties;

  const iconStyle = {
    borderRadius: '50%',
    backgroundColor: '#E9E9E9',
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  } as React.CSSProperties;

  return (
    <div
      id="operations-menu"
      style={style}
      onPointerMove={(event) => onSwipe(event)}
      onPointerCancel={() => onCancel('operationsMenu')}
    >
      {/* INVESTOR: Deposit or Deposit/Withdrawal */}
      {user?.AccountType === AccountType.Investor && investor?.AccountOptions?.InvestorWithdrawButton === 'Show' ? (
        <Link to={`/change-balance/${user.LinkToken ? linkedId : user?.AccountInfo?.Id}`}>
          <div className={OperationsMenuMobileCss.styleRow}>
            <span className="mx-3" style={iconStyle}>
              {Icons.ArrowsAltH(customColorsGenUser.dark, 'sm')}
            </span>
            <span className={OperationsMenuMobileCss.link}>
              <FormattedMessage id="operations.depositWithdrawal" />
            </span>
          </div>
        </Link>
      ) : null}

      {/* MASTER: Deposit/Withdrawal */}
      {/* It should use the Master's OwnFunds ID, instead of the Master's own ID */}
      {/* {user?.AccountType === AccountType.Master && user?.AccountInfo?.AccountOptions?.ShowMmOwnFunds === 'Show'
        ? (
          <Link to={`/change-balance/${user?.AccountInfo?.OwnFunds?.Id}`}>
            <div className="row mb-3 d-flex flex-nowrap align-items-center">
              <span className="mx-3" style={iconStyle}>
                {Icons.ArrowsAltH(customColorsGenUser.dark, 'sm')}
              </span>
              <span className={OperationsMenuMobileCss.link}>
                <FormattedMessage id="operations.depositWithdrawal" />
              </span>
            </div>
          </Link>
        ) : null} */}

      {/* INVESTOR: Credit In/Out */}
      {user?.AccountType === AccountType.Investor && investor?.AccountOptions?.InvestorCreditButton === 'Show' ? (
        <Link to={`/change-credit/${user.LinkToken ? linkedId : user?.Id}`}>
          <div className={OperationsMenuMobileCss.styleRow}>
            <span className="mx-3" style={iconStyle}>
              {Icons.ArrowsAltH(customColorsGenUser.dark, 'sm')}
            </span>
            <span className={OperationsMenuMobileCss.link}>
              <FormattedMessage id="operations.creditInOut" />
            </span>
          </div>
        </Link>
      ) : null}

      {/* INVESTOR: DISCONNECT */}
      {user?.AccountType === AccountType.Investor && showDisconnectFromThisMasterWarning ? (
        <Link to="/#" onClick={handleDetachInvestor}>
          <div className={OperationsMenuMobileCss.styleRow}>
            <span className="mx-3" style={iconStyle}>
              <FontAwesomeIcon size="sm" color={customColors.dark} icon={faSignOutAlt} />
            </span>
            <span className={OperationsMenuMobileCss.link}>
              <FormattedMessage id="button.disconnect" />
            </span>
          </div>
        </Link>
      ) : null}

      {/* INVESTOR: AUTO DISCONNECT */}
      {user?.AccountType === AccountType.Investor && investor?.AccountOptions?.ChangeSlTpButton === 'Show' ? (
        <Link to={`/edit-slTp/${investor.Id}`}>
          <div className={OperationsMenuMobileCss.styleRow}>
            <span className="mx-3" style={iconStyle}>
              <FontAwesomeIcon size="sm" color={customColors.dark} icon={faSignOutAlt} />
            </span>
            <span className={OperationsMenuMobileCss.link}>
              <FormattedMessage id="button.autoDisconnect" />
            </span>
          </div>
        </Link>
      ) : null}

      {/* An empty space, if user IS Investor, but can't see their Credit button */}
      {/* {user?.AccountType === AccountType.Investor && investor?.AccountOptions?.InvestorCreditButton === 'Hide'
        ? (
          <div className={OperationsMenuMobileCss.mb}>
            {/* space */}
      {/* </div> */}
      {/* ) : null} */}

      {/* MASTER: Credit In/Out */}
      {/* {user?.AccountType === AccountType.Master && user?.AccountInfo?.AccountOptions?.ShowMmOwnFunds === 'Show'
        ? (
          <Link to={`/change-credit/${user?.AccountInfo?.OwnFunds?.Id}`}>
            <div className={OperationsMenuMobileCss.styleRow}>
              <span className="mx-3" style={iconStyle}>
                {Icons.ArrowsAltH(customColorsGenUser.dark, 'sm')}
              </span>
              <span className={OperationsMenuMobileCss.link}>
                <FormattedMessage id="operations.creditInOut" />
              </span>
            </div>
          </Link>
        ) : null} */}
      {/* An empty space, if user IS Master, but can't see their OwnFunds account */}
      {/* {user?.AccountType === AccountType.Master && user?.AccountInfo?.AccountOptions?.ShowMmOwnFunds === 'Hide'
        ? (
          <div className={OperationsMenuMobileCss.mb}>
            {/* space */}
      {/* </div> */}
      {/* ) : null} */}

      {/* MASTER and INVESTOR: Orders Commissions */}

      <Link to={`/commissions/${user?.LinkToken ? linkedId : user?.AccountInfo?.Id}`}>
        <div className={OperationsMenuMobileCss.styleRow}>
          <span className="mx-3" style={iconStyle}>
            {Icons.DollarSignIcon(customColorsGenUser.dark, 'sm')}
          </span>
          <span className={OperationsMenuMobileCss.link}>
            <FormattedMessage id="operations.commissions" />
          </span>
        </div>
      </Link>

      {/* MASTER: Connected Investors */}
      {user?.AccountType === AccountType.Master && user?.AccountInfo?.AccountOptions?.MmConnectedInvestors === 'Show' ? (
        <Link to={`/connected-investors/${user?.AccountInfo?.Id}`}>
          <div className={OperationsMenuMobileCss.styleRow}>
            <span className="mx-3" style={iconStyle}>
              {Icons.UsersIcon(customColorsGenUser.dark, 'sm')}
            </span>
            <span className={OperationsMenuMobileCss.link}>
              <FormattedMessage id="operations.connectedInvestors" />
            </span>
          </div>
        </Link>
      ) : (
        <div className={OperationsMenuMobileCss.mb}>{/* space */}</div>
      )}

      {/* History: Always show for INVESTOR and MASTER */}
      <Link to={`/history/${user?.LinkToken ? linkedId : user?.AccountInfo?.Id}`}>
        <div className={OperationsMenuMobileCss.styleRow}>
          <span className="mx-3" style={iconStyle}>
            {Icons.HistoryIcon(customColorsGenUser.dark, 'sm')}
          </span>
          <span className={OperationsMenuMobileCss.link}>
            <FormattedMessage id="operations.history" />
          </span>
        </div>
      </Link>

      {/* Statements: Condition for INVESTOR, but always show for MASTER */}
      {investor?.AccountOptions?.InvestorStatementButton === 'Show' || user?.AccountType === AccountType.Master ? (
        <Link to={`/download-statement/${user?.LinkToken ? linkedId : user?.AccountInfo?.Id}`}>
          <div className={OperationsMenuMobileCss.styleRow}>
            <span className="mx-3" style={iconStyle}>
              {Icons.FileAltIcon(customColorsGenUser.dark, 'sm')}
            </span>
            <span className={OperationsMenuMobileCss.link}>
              <FormattedMessage id="operations.statements" />
            </span>
          </div>
        </Link>
      ) : (
        <div className={OperationsMenuMobileCss.mb}>{/* space */}</div>
      )}

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className={OperationsMenuMobileCss.styleRow} onClick={handleShowLanguagesMenu}>
        <span className="mx-3" style={iconStyle}>
          {Icons.LanguageIcon(customColorsGenUser.dark, 'sm')}
        </span>
        <span className="mb-1">{languages[currentLang] || languages.en}</span>
      </div>
    </div>
  );
};

export default withRouter(OperationsMenuMobile);
