/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Location } from 'history';
import { useSelector } from 'react-redux';
import { spaceSeparatedThousands } from '../../utils/number';
import styles from './investor/cssModules/GenUserLinkedAccountsListItem.module.css';
import { FundsType, Master } from '../../types/masterTypes';
import { PAMMState } from '../../redux/reducers/rootReducer';
import WalletNavBarIcon from '../../icons/WalletNavBarIcon';
import { Summary } from '../../types/sharedTypes';
import EquityDisplay from '../shared/EquityDisplay';

interface GenUserLinkedAccountsListItemProps {
  expandedMenu: boolean;
}

const GenUserMasterList: React.FC<GenUserLinkedAccountsListItemProps> = ({ expandedMenu }): JSX.Element => {
  const history = useHistory();

  const location: Location = useLocation();
  const master: Master = useSelector((state: PAMMState) => state.masterReducer.master);
  const isOnOwnFunds = location.pathname === '/ownFunds';
  const isOnDashboard = location.pathname === '/dashboard';

  const moneyManagerStyle = isOnDashboard || (!isOnOwnFunds && !isOnDashboard) ? 'selectedId' : 'secondId';
  const ownFundsStyle = isOnOwnFunds ? 'selectedId' : 'secondId';

  const profitMaster: Array<Summary> = useSelector((state: PAMMState) => state.profitReducer.profitMaster);
  const profitOwnFunds: Array<Summary> = useSelector((state: PAMMState) => state.profitReducer.profitOwnFunds);

  const handleBlockClick = (block: FundsType) => {
    if (block === FundsType.OwnFunds) {
      history.push('/ownFunds');
    } else {
      history.push('/dashboard');
    }
  };

  return (
    <>
      {expandedMenu ? (
        <>
          <div className={styles[`${moneyManagerStyle}`]} onClick={() => handleBlockClick(FundsType.TotalFunds)}>
            <div className={styles['auto-layout-vertical']}>
              <div className={styles['div']}>
                <span>
                  <WalletNavBarIcon size={16} />
                </span>
                <div className={`${styles['text-wrapper']} text-small-semibold`}>
                  <FormattedMessage id="moneyManager" />
                </div>
              </div>
              <div className={`${styles[`text-wrapper-2-${moneyManagerStyle}`]} text-inputs`}>
                {master.Name} (<FormattedMessage id="id" />: {master.Id})
              </div>
            </div>
            <div className={styles['auto-layout-vertical-2']}>
              <div className={`${styles[`text-wrapper-3-${moneyManagerStyle}`]} text-semibold`}>
                {spaceSeparatedThousands(master.Equity)}
              </div>
              <EquityDisplay isSelected={isOnDashboard} equityValue={profitMaster[0]?.Value} styleName="div-2" />
            </div>
          </div>
          <div className={`${styles[`${ownFundsStyle}`]} mt-2`} onClick={() => handleBlockClick(FundsType.OwnFunds)}>
            <div className={styles['auto-layout-vertical']}>
              <div className={styles['div']}>
                <span>
                  <WalletNavBarIcon size={16} />
                </span>
                <div className={`${styles['text-wrapper']} text-small-semibold`}>
                  <FormattedMessage id="ownFunds" />
                </div>
              </div>
              <div className={`${styles[`text-wrapper-2-${ownFundsStyle}`]} text-inputs`}>
                {master.Name} (<FormattedMessage id="id" />: {master.OwnFunds.Id})
              </div>
            </div>
            <div className={styles['auto-layout-vertical-2']}>
              <div className={`${styles[`text-wrapper-3-${ownFundsStyle}`]} text-semibold`}>
                {spaceSeparatedThousands(master.OwnFunds.Equity)}
              </div>
              <EquityDisplay isSelected={isOnOwnFunds} equityValue={profitOwnFunds[0]?.Value} styleName="div-2" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles[`${moneyManagerStyle}-small`]} onClick={() => handleBlockClick(FundsType.TotalFunds)}>
            <div className={styles['auto-layout-vertical']}>
              <div className={styles['div']}>
                <span>
                  <WalletNavBarIcon size={12} />
                </span>
                <div className={`${styles['text-wrapper']} text-small-semibold`}>
                  <FormattedMessage id="linkedAccountsListWallet.smallMenu.moneyManager" />
                </div>
              </div>
            </div>
            <div className={`${styles[`text-wrapper-5-${moneyManagerStyle}`]} text-inputs`}>
              {spaceSeparatedThousands(master?.Equity)} {master.Currency}
            </div>
          </div>
          <div className={`${styles[`${ownFundsStyle}-small`]} mt-2`} onClick={() => handleBlockClick(FundsType.OwnFunds)}>
            <div className={styles['auto-layout-vertical']}>
              <div className={styles['div']}>
                <span>
                  <WalletNavBarIcon size={12} />
                </span>
                <div className={`${styles['text-wrapper']} text-small-semibold`}>
                  <FormattedMessage id="ownFunds" />
                </div>
              </div>
            </div>
            <div className={`${styles[`text-wrapper-5-${ownFundsStyle}`]} text-inputs`}>
              {spaceSeparatedThousands(master.OwnFunds.Equity)} {master.Currency}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GenUserMasterList;
