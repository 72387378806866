/* eslint-disable no-unused-expressions */
import { Dispatch } from 'redux';
import { Direction } from '@t4b/core';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { PaginationInfo } from '../../../types/sharedTypes';
import setLoadingStatus from '../../../redux/actions/setLoadingStatus';

export const FETCH_INVESTORS_REPORT = 'FETCH_INVESTORS_REPORT';

export type FetchInvestorsReportAction = {
  type: string;
  investorsReport: Array<any>;
  paginationInfo: PaginationInfo;
};

const fetchInvestorsReport = (investorsReport: Array<any>, paginationInfo: PaginationInfo): FetchInvestorsReportAction => {
  return {
    type: FETCH_INVESTORS_REPORT,
    investorsReport,
    paginationInfo
  };
};

export const buildQueryString = (
  page?: number,
  limit?: number,
  sortField?: string,
  sortDirection?: Direction,
  filtered?: Array<any>
) => {
  const params = new URLSearchParams();

  if (page) params.append('page', String(page));
  if (limit) params.append('limit', String(limit));
  if (sortField) params.append('sortField', sortField);
  if (sortDirection) params.append('sortDirection', sortDirection);

  const filterMapping: Record<string, string> = {
    mtserver: 'investorMtServerFilter',
    mtlogin: 'investorMtLoginFilter',
    pammid: 'investorIdFilter',
    mmpammid: 'mmIdFilter',
    balancefrom: 'balanceFrom',
    balanceto: 'balanceTo',
    equityfrom: 'equityFrom',
    equityto: 'equityTo',
    investmentsfrom: 'investmentsFrom',
    investmentsto: 'investmentsTo',
    totalprofitfrom: 'totalProfitFrom',
    totalprofitto: 'totalProfitTo',
    createdfrom: 'createdFrom',
    createdto: 'createdTo'
  };

  filtered?.forEach(({ id, value }) => {
    const mappedKey = filterMapping[id];
    if (mappedKey) {
      params.append(mappedKey, id === 'createdfrom' || id === 'createdto' ? String(value / 1000) : value);
    }
  });

  return params.toString();
};

const fetchInvestorsReportAsync = (
  api: string,
  page?: number,
  limit?: number,
  sortField?: string,
  sortDirection?: Direction,
  filtered?: Array<any>
) => {
  const queryString = buildQueryString(page, limit, sortField, sortDirection, filtered);

  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/investors/summary?${queryString}`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((data: any) => dispatch(fetchInvestorsReport(data.Data, data.PaginationInfo)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchInvestorsReportAsync;
