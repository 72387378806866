function putValuesToLocalStorage(key: string, values: string[]): void {
  localStorage.setItem(key, values.join(' '));
}

function putValueToLocalStorage(key: string, value: string): void {
  localStorage.setItem(key, value);
}

function getValueFromLocalStorage(key: string): string | null {
  return localStorage.getItem(key);
}

function getValuesFromLocalStorage(key: string): string[] | null {
  const searchResult = localStorage.getItem(key);
  return searchResult ? searchResult.split(' ') : null;
}

function removeValueFromLocalStorage(key: string): void {
  localStorage.removeItem(key);
}

function localStorageKeyExists(key: string): boolean {
  return localStorage.getItem(key) !== null;
}

function setValuesOnEvent(eventKey: string, onEvent: () => void): void {
  window.onstorage = (event: StorageEvent) => {
    if (event.key === eventKey) {
      onEvent();
    }
  };
}

const LocalStorageUtils = {
  putValuesToLocalStorage,
  getValuesFromLocalStorage,
  setValuesOnEvent,
  putValueToLocalStorage,
  removeValueFromLocalStorage,
  localStorageKeyExists,
  getValueFromLocalStorage
};

export default LocalStorageUtils;
