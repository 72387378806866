/* eslint-disable no-restricted-syntax */
const fontSizes = {
  normal: {
    '--font-size-desk-h1': '28px',
    '--font-size-desk-h2': '24px',
    '--font-size-desk-h3': '20px',
    '--font-size-desk-h4': '18px',
    '--font-size-desk-h4-semibold': '18px',
    '--font-size-desk-regular': '16px',
    '--font-size-desk-semibold': '16px',
    '--font-size-desk-small': '14px',
    '--font-size-desk-small-semibold': '14px',
    '--font-size-desk-inputs': '12px'
  },
  small: {
    '--font-size-desk-h1': '24px',
    '--font-size-desk-h2': '20px',
    '--font-size-desk-h3': '18px',
    '--font-size-desk-h4': '16px',
    '--font-size-desk-h4-semibold': '16px',
    '--font-size-desk-regular': '14px',
    '--font-size-desk-semibold': '14px',
    '--font-size-desk-small': '12px',
    '--font-size-desk-small-semibold': '11px',
    '--font-size-desk-inputs': '10px'
  },
  large: {
    '--font-size-desk-h1': '32px',
    '--font-size-desk-h2': '26px',
    '--font-size-desk-h3': '24px',
    '--font-size-desk-h4': '20px',
    '--font-size-desk-h4-semibold': '20px',
    '--font-size-desk-regular': '18px',
    '--font-size-desk-semibold': '18px',
    '--font-size-desk-small': '16px',
    '--font-size-desk-small-semibold': '16px',
    '--font-size-desk-inputs': '14px'
  }
};

function setFontSizes(json: any): void {
  for (const [key, value] of Object.entries<string>(fontSizes[json.fontSize as keyof typeof fontSizes])) {
    document.documentElement.style.setProperty(key, value);
  }
}
export default setFontSizes;
