import '@t4b/core/lib/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import 'react-toastify/dist/ReactToastify.css';
import App from './app';
import './app.scss';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
