import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Modal, Form, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { UpdateMaster, Master, MasterMode } from '../../../../types/masterTypes';
import updateMaster from '../../../../redux/actions/master/updateMaster';
import { PAMMState } from '../../../../redux/reducers/rootReducer';
import SimpleInput from '../../../shared/SimpleInput';
import InputUtils from '../../../../utils/validate-schema';
import LocalStorageUtils from '../../../../utils/local-storage';
import { AccountType, User } from '../../../../types/authTypes';
import { AccountModificationSchemas } from '../../../../common/validationSchems/accountModificationSchemas';
import PasswordTooltip from '../../investor/PasswordTooltip';

interface MasterEditModalProps {
  master: Master;
  show: boolean;
  toggleVisibility(modal: string): void;
}

const MasterEditModal: React.FC<MasterEditModalProps> = ({ master, show, toggleVisibility }): JSX.Element => {
  const inputStyle = {
    width: '15rem'
  };

  const dispatch = useDispatch();
  const intl = useIntl();
  const api: string = useSelector((state: PAMMState) => state.configReducer.api);
  const customColor = useSelector((state: PAMMState) => state.configReducer.customColor);

  const validation = new AccountModificationSchemas(intl);
  const schema = {
    Username: validation.username,
    Password: validation.passwordNotRequired,
    Name: validation.name,
    Email: validation.email,
    MinInvestment: validation.minInvestments
  };

  const originalMaster: Master = master;

  const [editMasterForm, setEditMasterForm] = React.useState<Master>(master);
  const [masterForTests, setMasterForTests] = React.useState<boolean>(false);
  const [privateMaster, setPrivateMaster] = React.useState<boolean>(false);
  const [invisibleMaster, setInvisibleMaster] = React.useState<boolean>(false);
  const userJSON = LocalStorageUtils.getValueFromLocalStorage('user');
  const [user, setUser] = React.useState<User>({} as User);

  const [showTooltip, setShowTooltip] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    if (typeof userJSON === 'string') {
      setUser(JSON.parse(userJSON));
    }
  }, [userJSON]);

  React.useEffect(() => {
    setEditMasterForm(master);
  }, [master]);

  React.useEffect(() => {
    setMasterForTests(master.Mode === 2);
    setPrivateMaster(master.Private);
    setInvisibleMaster(master.Invisible);
  }, [editMasterForm, master.Mode, master.Private, master.Invisible]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, property: string): void => {
    setEditMasterForm({
      ...editMasterForm,
      [property]: event.target.value
    });
  };

  const handleCancel = (): void => {
    setEditMasterForm(originalMaster);
    toggleVisibility('EditUser');
  };

  const handleSubmit = (): void => {
    const request: UpdateMaster = {
      Username: editMasterForm.Username,
      Password: editMasterForm.Password,
      Name: editMasterForm.Name,
      Email: editMasterForm.Email,
      AllocationMode: Number(editMasterForm.AllocationMode),
      Mode: masterForTests ? MasterMode.Test : MasterMode.Normal,
      MinInvestment: editMasterForm.MinInvestment,
      Private: privateMaster,
      Invisible: invisibleMaster,
      Bio: editMasterForm.Bio
    };

    if (!masterForTests && editMasterForm.Mode === MasterMode.Disabled) {
      delete request.Mode;
    }

    if (master.Username === request.Username) {
      delete request.Username;
    }
    if (master.Name === request.Name) {
      delete request.Name;
    }
    if (master.Email === request.Email) {
      delete request.Email;
    }
    if (master.AllocationMode === request.AllocationMode) {
      delete request.AllocationMode;
    }
    if (master.Mode === request.Mode) {
      delete request.Mode;
    }
    if (master.MinInvestment === request.MinInvestment) {
      delete request.MinInvestment;
    }
    if (master.Private === request.Private) {
      delete request.Private;
    }
    if (master.Invisible === request.Invisible) {
      delete request.Invisible;
    }
    if (master.Bio === request.Bio) {
      delete request.Bio;
    }

    if (JSON.stringify(request)[2] === undefined) {
      return;
    }

    dispatch(updateMaster(api, editMasterForm.Id.toString(), request));
    toggleVisibility('EditUser');
  };

  const isUpdateDisabled = InputUtils.validateSchema(Yup.object().shape(schema), editMasterForm);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const btnStyle = {
    backgroundColor: customColor,
    borderColor: customColor
  } as React.CSSProperties;

  return (
    <Modal show={show} onHide={handleCancel} centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="editUser" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="editUser">
        <Form>
          <SimpleInput
            state={editMasterForm}
            valueKey="Username"
            labelTranslateKey="masterDetails.modal.editMaster.username.label"
            schema={schema.Username}
            type="text"
            setState={setEditMasterForm}
            placeholder="masterDetails.modal.editMaster.username.placeholder"
            style={inputStyle}
            className="mx-auto"
            size="sm"
          />

          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onBlur={handleMouseLeave}
            onFocus={handleMouseEnter}
          >
            <SimpleInput
              state={editMasterForm}
              valueKey="Password"
              labelTranslateKey="investorDetails.modal.editInvestor.password.label"
              schema={schema.Password}
              type="text"
              setState={setEditMasterForm}
              placeholder="investorDetails.modal.editInvestor.password.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>

          {showTooltip && <PasswordTooltip userType={user.AccountType} accountType="master" top="38%" position="fixed" />}

          <SimpleInput
            state={editMasterForm}
            valueKey="Name"
            labelTranslateKey="masterDetails.modal.editMaster.name.label"
            schema={schema.Name}
            type="text"
            setState={setEditMasterForm}
            placeholder="masterDetails.modal.editMaster.name.placeholder"
            style={inputStyle}
            className="mx-auto"
            size="sm"
          />

          <SimpleInput
            state={editMasterForm}
            valueKey="Email"
            labelTranslateKey="masterDetails.modal.editMaster.email.label"
            schema={schema.Email}
            type="text"
            setState={setEditMasterForm}
            placeholder="masterDetails.modal.editMaster.email.placeholder"
            style={inputStyle}
            className="mx-auto"
            size="sm"
          />
          {user.AccountType === 'admin' ? (
            <Form.Group key="allocationMode" controlId="AllocationMode" style={inputStyle} className="mb-0 mt-0">
              <Form.Label className="mt-4 mb-1">
                <FormattedMessage id="allocationMode" />
              </Form.Label>
              <Form.Select
                size="sm"
                value={editMasterForm.AllocationMode}
                style={{ width: '100%' }}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleInputChange(event, event.target.id)}
              >
                <option value="0">{intl.formatMessage({ id: 'dropdown.method.byBalance' })}</option>
                <option value="1">{intl.formatMessage({ id: 'dropdown.method.byEquity' })}</option>
              </Form.Select>
            </Form.Group>
          ) : null}

          <SimpleInput
            state={editMasterForm}
            valueKey="MinInvestment"
            labelTranslateKey="masterDetails.modal.editMaster.minInvestment.label"
            schema={schema.MinInvestment}
            type="text"
            setState={setEditMasterForm}
            placeholder="masterDetails.modal.editMaster.minInvestment.placeholder"
            style={inputStyle}
            className="mx-auto"
            size="sm"
          />

          {user?.AccountType === AccountType.Master && (
            <>
              <Form.Label className="mt-4 mb-1">
                <FormattedMessage id="masterDetails.modal.bio.title" />
              </Form.Label>
              <Form.Control
                as="textarea"
                value={editMasterForm.Bio}
                style={{ height: 100, width: '100%' }}
                onChange={(e) => setEditMasterForm({ ...editMasterForm, Bio: e.target.value })}
              />
            </>
          )}

          {user?.AccountType === AccountType.Admin ? (
            <>
              <Form.Check
                inline
                label={intl.formatMessage({ id: 'masterDetails.modal.editMaster.privateMaster' })}
                type="checkbox"
                id="PrivateMaster"
                checked={privateMaster}
                onChange={() => setPrivateMaster(!privateMaster)}
              />

              <Form.Check
                inline
                label={intl.formatMessage({ id: 'masterDetails.modal.editMaster.invisibleMaster' })}
                type="checkbox"
                id="InvisibleMaster"
                checked={invisibleMaster}
                onChange={() => setInvisibleMaster(!invisibleMaster)}
              />

              <Form.Check
                inline
                label={intl.formatMessage({ id: 'masterDetails.modal.editMaster.masterForTests' })}
                type="checkbox"
                id="MasterForTests"
                checked={masterForTests}
                onChange={() => setMasterForTests(!masterForTests)}
              />
            </>
          ) : null}
          {/* <Form.Label className="mt-4 mb-0">
            <span className='text-danger'>*</span> —{' '}
            <FormattedMessage id="createAccount.admin.adminInformation.requiredFields" />
          </Form.Label> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={handleCancel}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={() => handleSubmit()}
          disabled={isUpdateDisabled}
          style={user?.AccountType === AccountType.Admin ? {} : btnStyle}
        >
          <FormattedMessage id="button.update" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MasterEditModal;
