import React, { useState } from 'react';

import { withRouter } from 'react-router-dom';

import styles from '../shared/styles/GenUserTopbar.module.css';

import manualIcon from '../../icons/manual.svg';
import notificationActiveIcon from '../../icons/notification-icon-active.svg';

import LanguageDropdown from '../shared/LanguageDropdown';

const GenUserTopbar: React.FC<any> = ({ signOutFunc }): JSX.Element => {
  // switches between notification icons (temporary until logic is written)
  const [notificationsAvailable, setNotificationsAvailable] = useState(false);

  // icons inline so that hover css works
  const logoutIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g clipPath="url(#clip0_4377_83568)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V26C4 26.5304 4.21071 27.0391 4.58579 27.4142C4.96086 27.7893 5.46957 28 6 28H21C21.2652 28 21.5196 27.8946 21.7071 27.7071C21.8946 27.5196 22 27.2652 22 27C22 26.7348 21.8946 26.4804 21.7071 26.2929C21.5196 26.1054 21.2652 26 21 26H6V4H21C21.2652 4 21.5196 3.89464 21.7071 3.70711C21.8946 3.51957 22 3.26522 22 3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2H6ZM25.208 9.792C25.0202 9.60423 24.7656 9.49874 24.5 9.49874C24.2345 9.49874 23.9798 9.60423 23.792 9.792C23.6042 9.97977 23.4987 10.2344 23.4987 10.5C23.4987 10.7656 23.6042 11.0202 23.792 11.208L26.586 14H13C12.7348 14 12.4804 14.1054 12.2929 14.2929C12.1054 14.4804 12 14.7348 12 15C12 15.2652 12.1054 15.5196 12.2929 15.7071C12.4804 15.8946 12.7348 16 13 16H26.586L23.792 18.792C23.6042 18.9798 23.4987 19.2344 23.4987 19.5C23.4987 19.7656 23.6042 20.0202 23.792 20.208C23.9798 20.3958 24.2345 20.5013 24.5 20.5013C24.7656 20.5013 25.0202 20.3958 25.208 20.208L29.708 15.708C29.8011 15.6151 29.875 15.5048 29.9254 15.3833C29.9758 15.2618 30.0018 15.1315 30.0018 15C30.0018 14.8685 29.9758 14.7382 29.9254 14.6167C29.875 14.4952 29.8011 14.3849 29.708 14.292L25.208 9.792Z"
          fill="#606060"
        />
      </g>
      <defs>
        <clipPath id="clip0_4377_83568">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const notificationIcon = (
    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <g id="icon" clipPath="url(#clip0_4377_83524)">
        <path
          id="Vector"
          d="M27.0915 23.1916C26.2879 22.4752 25.5844 21.654 24.9998 20.75C24.3617 19.5021 23.9792 18.1393 23.8748 16.7416V12.625C23.8804 10.4296 23.084 8.30786 21.6355 6.65827C20.1869 5.00869 18.1858 3.94484 16.0082 3.66663V2.59163C16.0082 2.29657 15.891 2.0136 15.6823 1.80497C15.4737 1.59634 15.1907 1.47913 14.8957 1.47913C14.6006 1.47913 14.3176 1.59634 14.109 1.80497C13.9004 2.0136 13.7832 2.29657 13.7832 2.59163V3.68329C11.6251 3.98157 9.64815 5.05184 8.21861 6.69588C6.78908 8.33992 6.00378 10.4463 6.00817 12.625V16.7416C5.9038 18.1393 5.52132 19.5021 4.88317 20.75C4.30891 21.6519 3.61667 22.4731 2.82484 23.1916C2.73595 23.2697 2.66471 23.3658 2.61585 23.4736C2.567 23.5814 2.54165 23.6983 2.5415 23.8166V24.95C2.5415 25.171 2.6293 25.3829 2.78558 25.5392C2.94186 25.6955 3.15382 25.7833 3.37484 25.7833H26.5415C26.7625 25.7833 26.9745 25.6955 27.1308 25.5392C27.287 25.3829 27.3748 25.171 27.3748 24.95V23.8166C27.3747 23.6983 27.3493 23.5814 27.3005 23.4736C27.2516 23.3658 27.1804 23.2697 27.0915 23.1916ZM4.27484 24.1166C5.05018 23.3676 5.73284 22.5283 6.30817 21.6166C7.11201 20.1095 7.58103 18.4466 7.68317 16.7416V12.625C7.65012 11.6483 7.81394 10.675 8.16487 9.76301C8.51581 8.85101 9.04668 8.01895 9.72588 7.31638C10.4051 6.61381 11.2187 6.0551 12.1183 5.67352C13.0179 5.29194 13.9851 5.0953 14.9623 5.0953C15.9395 5.0953 16.9067 5.29194 17.8064 5.67352C18.706 6.0551 19.5196 6.61381 20.1988 7.31638C20.878 8.01895 21.4089 8.85101 21.7598 9.76301C22.1107 10.675 22.2746 11.6483 22.2415 12.625V16.7416C22.3436 18.4466 22.8127 20.1095 23.6165 21.6166C24.1918 22.5283 24.8745 23.3676 25.6498 24.1166H4.27484Z"
          fill="#606060"
        />
        <path
          id="Vector_2"
          d="M14.9999 28.5667C15.5249 28.5546 16.0286 28.3572 16.4221 28.0095C16.8156 27.6618 17.0734 27.1862 17.1499 26.6667H12.7666C12.8453 27.2003 13.1152 27.6871 13.526 28.0366C13.9368 28.3861 14.4606 28.5745 14.9999 28.5667Z"
          fill="#606060"
        />
      </g>
      <defs>
        <clipPath id="clip0_4377_83524">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <>
      <div className={styles['topbar']}>
        <span style={{ marginRight: '14px' }}>
          <LanguageDropdown />
        </span>
        {/* TODO: Implement after MVP */}
        {/* <button type="button" className={styles['manual-button']}> */}
        {/*  <img src={manualIcon} alt="Manual" /> */}
        {/* </button> */}
        {/* <button type="button" className={`me-2 ${styles['notification-button']}`}> */}
        {/*  {notificationsAvailable ? <img src={notificationActiveIcon} alt="Notifications" /> : notificationIcon} */}
        {/* </button> */}
        <button type="button" className={styles['logout-button']} onClick={signOutFunc}>
          {logoutIcon}
        </button>
      </div>
    </>
  );
};

export default withRouter(GenUserTopbar);
