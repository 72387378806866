import {
  FETCH_DEPOSITS,
  FETCH_INVESTMENTS,
  FETCH_PROFIT,
  FETCH_PROFIT_PER_PERIOD,
  FETCH_VOLUMES,
  FETCH_INVESTORS_SUMMARY,
  FetchSummaryAction
} from '../../actions/shared/fetchSummary';
import { Summary } from '../../../types/sharedTypes';

export interface SummaryState {
  deposits: Array<Summary>;
  investments: Array<Summary>;
  profit: Array<Summary>;
  profitPerPeriod: Array<Summary>;
  volumes: Array<Summary>;
  investors: Array<Summary>;
}

const initialState: SummaryState = {
  deposits: [],
  investments: [],
  profit: [],
  profitPerPeriod: [],
  volumes: [],
  investors: []
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const summaryReducer = (state: SummaryState = initialState, action: FetchSummaryAction): SummaryState => {
  switch (action.type) {
    case FETCH_DEPOSITS:
      return {
        ...state,
        deposits: action.summary
      };
    case FETCH_INVESTMENTS:
      return {
        ...state,
        investments: action.summary
      };
    case FETCH_PROFIT:
      return {
        ...state,
        profit: action.summary
      };
    case FETCH_PROFIT_PER_PERIOD:
      return {
        ...state,
        profitPerPeriod: action.summary
      };
    case FETCH_VOLUMES:
      return {
        ...state,
        volumes: action.summary
      };
    case FETCH_INVESTORS_SUMMARY:
      return {
        ...state,
        investors: action.summary
      };
    default:
      return state;
  }
};

export default summaryReducer;
