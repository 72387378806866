export const areAllPropsEQFalse = (object: Record<string, boolean>): boolean => {
  return Object.keys(object).every((property) => object[property] === false);
};

export const convertAllPropsToBool = (object: Record<string, boolean>, bool: boolean): Record<string, boolean> => {
  const objectCopy = JSON.parse(JSON.stringify(object));

  Object.keys(objectCopy).forEach((property) => {
    objectCopy[property] = bool;
  });

  return objectCopy;
};
