import React from 'react';

const RequestsIcon: React.FC = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.5C2 3.84315 3.34315 2.5 5 2.5H19C20.6569 2.5 22 3.84315 22 5.5V19.5C22 21.1569 20.6569 22.5 19 22.5H5C3.34315 22.5 2 21.1569 2 19.5V16.9941L13.0158 16.9941L12.9981 19.428L17.76 15.995L13.0484 12.5L13.0303 14.9941L2 14.9941V5.5ZM10.9726 5.49973L6.21068 8.93271L10.9223 12.4278L10.9404 9.93359H21.971V7.93359H10.9549L10.9726 5.49973Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RequestsIcon;
