import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Modal, Form, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { UpdateInvestor, Investor } from '../../../../types/investorTypes';
import updateInvestor from '../../../../redux/actions/investor/updateInvestor';
import { PAMMState } from '../../../../redux/reducers/rootReducer';
import SimpleInput from '../../../shared/SimpleInput';
import InputUtils from '../../../../utils/validate-schema';
import { AccountType } from '../../../../types/authTypes';
import LocalStorageUtils from '../../../../utils/local-storage';
import { AccountModificationSchemas } from '../../../../common/validationSchems/accountModificationSchemas';
import PasswordTooltip from '../PasswordTooltip';

interface InvestorEditModalProps {
  investor: Investor;
  show: boolean;
  toggleVisibility(modal: string): void;
}

const InvestorEditModal: React.FC<InvestorEditModalProps> = ({ investor, show, toggleVisibility }): JSX.Element => {
  const inputStyle = {
    width: '15rem'
  };

  const dispatch = useDispatch();
  const intl = useIntl();
  const api: string = useSelector((state: PAMMState) => state.configReducer.api);
  const customColor = useSelector((state: PAMMState) => state.configReducer.customColor);

  const userJSON = LocalStorageUtils.getValueFromLocalStorage('user');
  const [user, setUser] = React.useState<any>();

  React.useEffect(() => {
    if (typeof userJSON === 'string') {
      setUser(JSON.parse(userJSON));
    }
  }, [userJSON]);

  const validation = new AccountModificationSchemas(intl);
  const schema = {
    Username: validation.username,
    Password: validation.passwordNotRequired,
    Name: validation.name,
    Email: validation.email,
    Phone: validation.phone,
    Country: validation.country,
    LinkToken: validation.linkToken,
    HighWaterMark: validation.highWaterMark
  };

  const originalInvestor: Investor = {
    ...investor,
    Password: ''
  };

  const [editInvestorForm, setEditInvestorForm] = React.useState<Investor>(investor);
  const [investorForTests, setInvestorForTests] = React.useState<boolean>(false);

  const [showTooltip, setShowTooltip] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    setEditInvestorForm(investor);
  }, [investor]);

  React.useEffect(() => {
    setInvestorForTests(investor.IsTestInvestor);
  }, [editInvestorForm, investor.IsTestInvestor]);

  const handleCancel = (): void => {
    setEditInvestorForm(originalInvestor);
    toggleVisibility('EditUser');
  };

  const handleSubmit = (): void => {
    const request: UpdateInvestor = {
      Username: editInvestorForm.Username,
      Name: editInvestorForm.Name,
      Password: editInvestorForm.Password,
      Email: editInvestorForm.Email,
      Country: editInvestorForm.Country,
      Phone: editInvestorForm.Phone,
      LinkToken: editInvestorForm.LinkToken,
      HighWaterMark: editInvestorForm.HighWaterMark,
      IsTestInvestor: investorForTests
    };

    if (user?.AccountType === AccountType.Investor || investor.Mode === Number(request.Mode)) {
      delete request.Mode;
    }
    if (investor.Username === request.Username) {
      delete request.Username;
    }
    if (investor.Name === request.Name) {
      delete request.Name;
    }
    if (investor.Password === request.Password) {
      delete request.Password;
    }
    if (investor.Email === request.Email) {
      delete request.Email;
    }
    if (investor.Country === request.Country) {
      delete request.Country;
    }
    if (investor.Phone === request.Phone) {
      delete request.Phone;
    }
    if (investor.LinkToken === request.LinkToken) {
      delete request.LinkToken;
    }
    if (investor.HighWaterMark === request.HighWaterMark) {
      delete request.HighWaterMark;
    }
    if (investor.IsTestInvestor === request.IsTestInvestor) {
      delete request.IsTestInvestor;
    }

    if (JSON.stringify(request)[2] === undefined) {
      return;
    }

    dispatch(updateInvestor(api, editInvestorForm.Id.toString(), request));
    toggleVisibility('EditUser');
  };

  const isUpdateDisabled = InputUtils.validateSchema(Yup.object().shape(schema), editInvestorForm);

  const btnStyle = {
    backgroundColor: customColor,
    borderColor: customColor
  } as React.CSSProperties;

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <Modal show={show} onHide={handleCancel} centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="investorDetails.modal.editInvestor.editInvestor" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="editUser">
        <Form>
          <SimpleInput
            state={editInvestorForm}
            valueKey="Username"
            labelTranslateKey="investorDetails.modal.editInvestor.username.label"
            schema={schema.Username}
            type="text"
            setState={setEditInvestorForm}
            placeholder="investorDetails.modal.editInvestor.username.placeholder"
            style={inputStyle}
            className="mx-auto"
            size="sm"
          />

          <div>
            <SimpleInput
              state={editInvestorForm}
              valueKey="Name"
              labelTranslateKey="investorDetails.modal.editInvestor.name.label"
              schema={schema.Name}
              type="text"
              setState={setEditInvestorForm}
              placeholder="investorDetails.modal.editInvestor.name.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>

          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onBlur={handleMouseLeave}
            onFocus={handleMouseEnter}
          >
            <SimpleInput
              state={editInvestorForm}
              valueKey="Password"
              labelTranslateKey="investorDetails.modal.editInvestor.password.label"
              schema={schema.Password}
              type="text"
              setState={setEditInvestorForm}
              placeholder="investorDetails.modal.editInvestor.password.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>
          {showTooltip && <PasswordTooltip userType={user.AccountType} accountType="investor" top="45%" position="fixed" />}

          <div>
            <SimpleInput
              state={editInvestorForm}
              valueKey="Email"
              labelTranslateKey="investorDetails.modal.editInvestor.email.label"
              schema={schema.Email}
              type="text"
              setState={setEditInvestorForm}
              placeholder="investorDetails.modal.editInvestor.email.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>

          <div>
            <SimpleInput
              state={editInvestorForm}
              valueKey="Phone"
              labelTranslateKey="investorDetails.modal.editInvestor.phone.label"
              schema={schema.Phone}
              type="text"
              setState={setEditInvestorForm}
              placeholder="investorDetails.modal.editInvestor.phone.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>

          <div>
            <SimpleInput
              state={editInvestorForm}
              valueKey="Country"
              labelTranslateKey="investorDetails.modal.editInvestor.country.label"
              schema={schema.Country}
              type="text"
              setState={setEditInvestorForm}
              placeholder="investorDetails.modal.editInvestor.country.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>

          {user?.AccountType === AccountType.Admin ? (
            <div>
              <Form.Check
                inline
                label={intl.formatMessage({ id: 'investorDetails.modal.editInvestor.investorForTests' })}
                type="checkbox"
                id="InvestorForTests"
                checked={investorForTests}
                onChange={() => setInvestorForTests(!investorForTests)}
              />
            </div>
          ) : null}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => handleCancel()}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={() => handleSubmit()}
          disabled={isUpdateDisabled}
          style={user?.AccountType === AccountType.Admin ? {} : btnStyle}
        >
          <FormattedMessage id="button.update" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvestorEditModal;
