/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Schema } from 'yup';
import InputUtils from '../../utils/validate-schema';

interface IInputProps {
  state: any;
  valueKey: string | number;
  labelTranslateKey?: string;
  schema: Schema<any>;
  type: 'text' | 'password' | 'number';
  placeholder: string;
  disabled?: boolean;
  className?: string;
  style?: any;
  size?: any;
  inputStyle?: any;
  inline?: boolean;
  acceptZero?: boolean;
  setState(newState: any): void;
  validField?: boolean;
  setValidField?(state: boolean): void;
  fieldsModified?: Record<string, boolean>;
  setFieldsModified?(state: Record<string, boolean>): void;
}

const SimpleInput: React.FC<IInputProps> = ({
  state,
  valueKey,
  schema,
  type,
  placeholder,
  setState,
  labelTranslateKey,
  disabled,
  className,
  style,
  size,
  inputStyle,
  inline,
  acceptZero,
  validField,
  setValidField,
  fieldsModified,
  setFieldsModified
}: IInputProps) => {
  const intl = useIntl();

  let errorMessageBody: JSX.Element = <></>;

  const [modified, setModified] = React.useState<boolean>(false);
  let isInvalid: any = false;

  const wrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!modified) setModified(true);

    if (setFieldsModified) {
      setFieldsModified({
        ...fieldsModified,
        [valueKey]: true
      });
    }

    const value = type === 'number' && event.target.value !== '' ? Number(event.target.value) : event.target.value;
    const newState = { ...state, [valueKey]: value };
    setState(newState);
    if (setValidField) setValidField(false);
  };
  if (modified) {
    isInvalid = InputUtils.validateSchema<any, any>(schema, state, valueKey);
  }

  if (disabled) {
    errorMessageBody = <div className="empty-error" />;
  } else if (isInvalid || validField) {
    isInvalid = InputUtils.validateSchema<any, any>(schema, state, valueKey);
    errorMessageBody = isInvalid.errors?.map((error: string, index: number) => (
      <Form.Control.Feedback key={`${error}-${index}`} type="invalid" className="text-danger">
        {error}
      </Form.Control.Feedback>
    ));
  }

  // const resultClassName = `flex-fill ${className || 'simple-input_min-width'}`;

  let value;

  if (state[valueKey] === 0) {
    value = 0;
  } else if (state[valueKey]) {
    value = state[valueKey];
  } else {
    value = '';
  }

  const inlineInputStyle = {
    borderRight: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    borderRadius: 0,
    fontSize: 11
  } as React.CSSProperties;

  const desktopBody = (
    <>
      <Form.Label className="mt-4 mb-1">
        <FormattedMessage id={labelTranslateKey} />
        {valueKey === 'Phone' ||
        valueKey === 'LinkToken' ||
        valueKey === 'ReferralCode' ||
        valueKey === 'Country' ||
        valueKey === 'MinInvestment' ||
        valueKey === 'Comment' ||
        labelTranslateKey === 'investorDetails.modal.editInvestor.highwatermark.label' ||
        labelTranslateKey === 'investorDetails.modal.editInvestor.email.label' ||
        labelTranslateKey === 'investorDetails.modal.editInvestor.name.label' ||
        labelTranslateKey === 'investorDetails.modal.editInvestor.username.label' ||
        labelTranslateKey === 'masterDetails.modal.editMaster.username.label' ||
        labelTranslateKey === 'masterDetails.modal.editMaster.name.label' ||
        labelTranslateKey === 'masterDetails.modal.editMaster.email.label' ||
        labelTranslateKey === 'masterDetails.modal.editMaster.minInvestment.label' ||
        labelTranslateKey === 'createAccount.investor.synchronizationInformation.mtlogin.label' ||
        labelTranslateKey === 'investorDetails.modal.editInvestor.password.label' ||
        labelTranslateKey === 'editUser.admin.name.label' ||
        labelTranslateKey === 'pammLogin' ||
        labelTranslateKey === 'editUser.admin.email.label' ||
        labelTranslateKey === 'editUser.admin.password.label' ? null : (
          <span style={{ color: '#CC0000' }}> *</span>
        )}
      </Form.Label>
      <Form.Control
        placeholder={intl.formatMessage({ id: placeholder })}
        autoComplete="new-password"
        disabled={disabled}
        type={type}
        value={acceptZero ? value : value || (valueKey === 'HighWaterMark' ? value : '')}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => wrapper(event)}
        isInvalid={disabled ? false : isInvalid}
        size={size || null}
        className={className}
      />
      {isInvalid ? errorMessageBody : <div className="empty-error" />}
    </>
  );

  const mobileBody = (
    <Row className="mb-3">
      <Col xs={4} className="pe-0">
        <Form.Label className="pt-1" style={{ fontSize: 12, fontWeight: 'bold' }}>
          <FormattedMessage id={labelTranslateKey} />
          {valueKey === 'Phone' ||
          valueKey === 'LinkToken' ||
          valueKey === 'Country' ||
          valueKey === 'Investments' ||
          valueKey === 'MinInvestment' ||
          valueKey === 'Comment' ||
          valueKey === 'Server' ||
          valueKey === 'MinInvestment' ||
          labelTranslateKey === 'login' ? null : (
            <span style={{ color: '#CC0000' }}> *</span>
          )}
        </Form.Label>
      </Col>
      <Col xs={8}>
        <Form.Control
          placeholder={intl.formatMessage({ id: placeholder })}
          autoComplete="new-password"
          disabled={disabled}
          type={type}
          value={acceptZero ? value : value || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => wrapper(event)}
          isInvalid={disabled ? false : isInvalid}
          size="sm"
          style={inline ? inlineInputStyle : {}}
          className={className}
        />
        {isInvalid ? errorMessageBody : <div className="empty-error" />}
      </Col>
    </Row>
  );

  return labelTranslateKey ? (
    inline ? (
      mobileBody
    ) : (
      desktopBody
    )
  ) : (
    <div className="form-group" style={style || null}>
      <Form.Control
        placeholder={intl.formatMessage({ id: placeholder })}
        autoComplete="new-password"
        disabled={disabled}
        type={type}
        value={acceptZero ? value : value || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => wrapper(event)}
        isInvalid={disabled ? false : isInvalid}
        size={size || null}
        style={inputStyle || null}
        className={className}
      />
      {isInvalid || validField ? errorMessageBody : <div className="empty-error" />}
    </div>
  );
};

export default SimpleInput;
