import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { ILoadOrders } from '../../../types/maintenanceTypes';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_LOAD_ORDERS = 'FETCH_LOAD_ORDERS';

export type LoadOrdersAction = {
  type: string;
  loadOrders: ILoadOrders;
};

const fetchLoadOrders = (loadOrders: ILoadOrders): LoadOrdersAction => {
  return {
    type: FETCH_LOAD_ORDERS,
    loadOrders
  };
};

const fetchLoadOrdersByTicketsAsync = (api: string, request: ILoadOrders) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/maintenance/its/loadOrders/byTickets`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((loadOrders: any) => {
        dispatch(fetchLoadOrders(loadOrders));
        toast.success('Load Orders successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchLoadOrdersByTicketsAsync;
