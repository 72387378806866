/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import styles from '../shared/styles/GenUserNavbar.module.css';

import { PAMMState } from '../../redux/reducers/rootReducer';
import fetchRequestsCountAsync from '../../redux/actions/requests/fetchRequestsCount';
import LocalStorageUtils from '../../utils/local-storage';
import { AccountType } from '../../types/authTypes';
import InvestorEditModal from '../administrator/investor/modals/InvestorEditModal';
import { Investor } from '../../types/investorTypes';
import { Master } from '../../types/masterTypes';
import MasterEditModal from '../administrator/master/modals/MasterEditModal';
import GenUserFeedbackPopupModal from './GenUserFeedbackPopupModal';

import fetchInvestorDetailsAsync from '../../redux/actions/investor/fetchInvestorDetails';
import fetchMasterDetailsAsync from '../../redux/actions/master/fetchMasterDetails';
import { fetchSharedSettingsAsync } from '../../redux/actions/shared/fetchConfig';

import walletHeader from '../../icons/wallet-header.svg';
import navbarLogo from '../../logos/navbar-logo.png';
import navbarLogoSmall from '../../logos/navbar-logo-small.png';
import collapseIcon from '../../icons/collapse-nav.svg';
import expandIcon from '../../icons/expand-nav.svg';
import GenUserLinkedAccountsList from './GenUserLinkedAccountsList';
import ArrowUpDownIcon from '../../icons/ArrowUpDownIcon';
import fetchInvestorsAsync from '../../redux/actions/investor/fetchInvestors';
import GenUserMasterList from './GenUserMasterList';
import HomePageIcon from '../../icons/HomePageIcon';
import MoneyManagersIcon from '../../icons/MoneyManagersIcon';
import WalletsIcon from '../../icons/WalletsIcon';
import RequestsIcon from '../../icons/RequestsIcon';
import AccountSettingsIcon from '../../icons/AccountSettingsIcon';
import { applyStyles } from '../../utils/applyStyles';
import DashboardIcon from '../../icons/DashboardIcon';
import { PopupStatus, ProfitMode, CalcMethod, GraphicsPeriodTypes } from '../../types/sharedTypes';
import fetchProfitAsync from '../../redux/actions/shared/fetchProfit';
import PAMMDateTime from '../../utils/date-time';

const GenUserNavbar: React.FC<any> = ({ location }): JSX.Element => {
  const dispatch = useDispatch();

  // PAMM-2341 TODO MERGE
  const showRequestTab = LocalStorageUtils.getValueFromLocalStorage('showRequestTab');
  const sharedConfig = useSelector((state: PAMMState) => state.configReducer.sharedConfig);

  const api: string = useSelector((state: PAMMState) => state.configReducer.api);

  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);
  const linkedAccounts: Array<Investor> = useSelector((state: PAMMState) => state.investorsReducer.investors);
  const master: Master = useSelector((state: PAMMState) => state.masterReducer.master);

  const user = JSON.parse(LocalStorageUtils.getValueFromLocalStorage('user') || '');

  const [showEditInvestorModal, setShowEditInvestorModal] = React.useState<boolean>(false);
  const [showEditMasterModal, setShowEditMasterModal] = React.useState<boolean>(false);

  const [expandedMenu, setExpandedMenu] = React.useState<boolean>(true);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const linkedId = LocalStorageUtils.getValueFromLocalStorage('linkedIdMobile') || user.Id;
  const profileId = user?.AccountType === AccountType.Master ? master?.Id : investor?.Id;

  const registrationDate = user?.AccountInfo?.IsOwnFunds === false ? investor?.CreationTime : user?.AccountInfo?.RegistrationDate;

  React.useEffect(() => {
    if (user?.Id) {
      const accountId = user?.Id;
      const ownFundsId = user?.AccountInfo?.OwnFunds?.Id;
      let investorId = 0;

      if (user.AccountType === AccountType.Master) {
        if (location.pathname.includes('investors') && investor.CreationTime) {
          const match = location.pathname.match(/\/investors\/(\d+)/);
          investorId = match ? match[1] : null;

          dispatch(
            fetchProfitAsync(
              api,
              investorId,
              GraphicsPeriodTypes.AllTime,
              new PAMMDateTime(investor.CreationTime).getDateObject(),
              new Date(),
              CalcMethod.Regular,
              false,
              false,
              ProfitMode.Percent,
              AccountType.Investor
            )
          );
        }

        dispatch(
          fetchProfitAsync(
            api,
            accountId,
            GraphicsPeriodTypes.AllTime,
            new PAMMDateTime(registrationDate).getDateObject(),
            new Date(),
            CalcMethod.Regular,
            false,
            false,
            ProfitMode.Percent,
            AccountType.Master
          )
        );
        dispatch(
          fetchProfitAsync(
            api,
            ownFundsId,
            GraphicsPeriodTypes.AllTime,
            new PAMMDateTime(registrationDate).getDateObject(),
            new Date(),
            CalcMethod.Regular,
            false,
            false,
            ProfitMode.Percent,
            AccountType.OwnFunds
          )
        );
      } else if (user.AccountType === AccountType.Investor && !user.LinkToken && investor.CreationTime) {
        dispatch(
          fetchProfitAsync(
            api,
            accountId,
            GraphicsPeriodTypes.AllTime,
            new PAMMDateTime(registrationDate).getDateObject(),
            new Date(),
            CalcMethod.Regular,
            false,
            false,
            ProfitMode.Percent,
            AccountType.Investor
          )
        );
      }
    }
  }, [dispatch, api, location.pathname, investor]);

  const fetchProfitForAccount = (accountId: number, creationTime: string) => {
    if (user.LinkToken) {
      dispatch(
        fetchProfitAsync(
          api,
          accountId,
          GraphicsPeriodTypes.AllTime,
          new PAMMDateTime(creationTime).getDateObject(),
          new Date(),
          CalcMethod.Regular,
          true,
          false,
          ProfitMode.Percent
        )
      );
    }
  };

  React.useEffect(() => {
    linkedAccounts.forEach((investorAccount) => {
      fetchProfitForAccount(investorAccount.Id, investorAccount.CreationTime);
    });
  }, [dispatch, api, linkedAccounts.length]);

  React.useEffect(() => {
    if (user && user?.AccountInfo?.LinkToken)
      dispatch(fetchInvestorsAsync(api, user?.AccountInfo?.LinkToken, undefined, undefined, 1, 30));
  }, [dispatch, api]);

  // PAMM-2341 TODO
  React.useEffect(() => {
    dispatch(fetchSharedSettingsAsync(api));
  }, [dispatch, api]);

  React.useEffect(() => {
    if (user?.AccountType === AccountType.Master) {
      dispatch(fetchMasterDetailsAsync(api, user?.Id));
    }
  }, [dispatch, api]);

  React.useEffect(() => {
    dispatch(fetchRequestsCountAsync(api));
  }, [dispatch, api]);

  React.useEffect(() => {
    if (user?.Id && user?.AccountType === AccountType.Investor) {
      let investorId = 0;

      if (user?.AccountType === AccountType.Investor) {
        if (user?.AccountInfo?.LinkToken) {
          investorId = Number(linkedId);
        } else {
          investorId = user?.Id;
        }
      }

      dispatch(fetchInvestorDetailsAsync(api, investorId));
    }
  }, [dispatch, api, linkedId]);

  const toggleModalVisibility = (): void => {
    if (user?.AccountType === 'investor') {
      setShowEditInvestorModal(!showEditInvestorModal);
    }
    if (user?.AccountType === 'master') {
      setShowEditMasterModal(!showEditMasterModal);
    }
  };

  const MenuItemLarge: React.FC<{ to: string; icon: JSX.Element; textId: string }> = ({ to, icon, textId }): JSX.Element => {
    return (
      <NavLink
        to={to}
        className={applyStyles([styles['navbar-menu-item'], 'text-h4'])}
        activeClassName={applyStyles([styles['item-active'], 'text-h4-semibold'])}
      >
        <span>{icon}</span>
        <FormattedMessage id={textId} />
      </NavLink>
    );
  };

  const MenuItemSmall: React.FC<{ to: string; icon: JSX.Element }> = ({ to, icon }): JSX.Element => {
    return (
      <NavLink to={to} className={styles['navbar-menu-item-small']} activeClassName={styles['item-active-small']}>
        {icon}
      </NavLink>
    );
  };

  const feedbackPopupStatusStr = localStorage.getItem('feedbackPopupStatus');
  const showFeedbackPopup =
    feedbackPopupStatusStr === null ? true : PopupStatus[feedbackPopupStatusStr as keyof typeof PopupStatus] === PopupStatus.Open;

  const menuLarge = (
    <>
      <div className={styles['navbar-wallets-container']}>
        <button
          className={styles['wallet-header']}
          style={{ marginBottom: `${isOpen ? '' : '24px'}` }}
          onClick={investor.LinkToken || user?.AccountType === AccountType.Master ? () => setIsOpen(!isOpen) : undefined}
        >
          <img alt="Vector" src={walletHeader} />
          <div className="text-h4-semibold">
            <FormattedMessage id="genUserNavbar.wallets" />
          </div>
          {investor.LinkToken || user?.AccountType === AccountType.Master ? (
            <ArrowUpDownIcon isOpen={isOpen} fill="white" />
          ) : null}
        </button>
        {user?.AccountType === AccountType.Investor ? (
          <GenUserLinkedAccountsList expandedMenu={expandedMenu} isOpen={isOpen} linkedAccounts={linkedAccounts} />
        ) : (
          !isOpen && <GenUserMasterList expandedMenu={expandedMenu} />
        )}
      </div>
      <div className={styles['navbar-menu-container']}>
        <div className={styles['navbar-menu-header']}>
          <FormattedMessage id="genUserNavbar.menu" />
        </div>

        {user?.AccountType === AccountType.Investor ? (
          <MenuItemLarge to="/dashboard" icon={<HomePageIcon />} textId="navbar.mainpage" />
        ) : (
          <MenuItemLarge to="/dashboard" icon={<DashboardIcon />} textId="navbar.dashboard" />
        )}

        {user?.AccountType === AccountType.Investor && investor?.AccountOptions?.MasterList === 'Show' ? (
          <MenuItemLarge to="/money_managers" icon={<MoneyManagersIcon />} textId="navbar.masters" />
        ) : null}

        <MenuItemLarge
          to="/wallets"
          icon={<WalletsIcon />}
          textId={user?.AccountType === AccountType.Investor ? 'navbar.wallets' : 'navbar.wallet'}
        />

        {showRequestTab !== 'false' && sharedConfig?.RequestTab?.toString() !== 'false' ? (
          <MenuItemLarge to="/requests" icon={<RequestsIcon />} textId="requests.title" />
        ) : null}

        <MenuItemLarge to={`/editProfile/${profileId}`} icon={<AccountSettingsIcon />} textId="accountSettings" />
        {showFeedbackPopup && <GenUserFeedbackPopupModal />}
      </div>
    </>
  );

  const menuSmall = (
    <>
      <div className={styles['navbar-wallets-container-small']}>
        <button
          className={styles['wallet-header-small']}
          style={{ marginBottom: `${isOpen ? '' : '24px'}` }}
          onClick={user.LinkToken || user?.AccountType === AccountType.Master ? () => setIsOpen(!isOpen) : undefined}
        >
          <div className="text-h4-semibold">
            <FormattedMessage id="genUserNavbar.wallets" />
          </div>
          {user.LinkToken || user?.AccountType === AccountType.Master ? <ArrowUpDownIcon isOpen={isOpen} fill="white" /> : null}
        </button>
        {user?.AccountType === AccountType.Investor ? (
          <GenUserLinkedAccountsList expandedMenu={expandedMenu} isOpen={isOpen} linkedAccounts={linkedAccounts} />
        ) : (
          !isOpen && <GenUserMasterList expandedMenu={expandedMenu} />
        )}
      </div>
      <div className={styles['navbar-menu-container']}>
        <div className={styles['navbar-menu-header-small']}>
          <FormattedMessage id="genUserNavbar.menu" />
        </div>

        <MenuItemSmall to="/dashboard" icon={user?.AccountType === AccountType.Investor ? <HomePageIcon /> : <DashboardIcon />} />

        {user?.AccountType === AccountType.Investor && investor?.AccountOptions?.MasterList === 'Show' ? (
          <MenuItemSmall to="/money_managers" icon={<MoneyManagersIcon />} />
        ) : null}

        <MenuItemSmall to="/wallets" icon={<WalletsIcon />} />

        {showRequestTab !== 'false' && sharedConfig?.RequestTab?.toString() !== 'false' ? (
          <MenuItemSmall to="/requests" icon={<RequestsIcon />} />
        ) : null}

        <MenuItemSmall to={`/editProfile/${profileId}`} icon={<AccountSettingsIcon />} />
      </div>
    </>
  );

  return (
    <>
      <div
        className={
          expandedMenu ? `${styles['navbar']} ${styles['navbar-large']}` : `${styles['navbar']} ${styles['navbar-small']}`
        }
      >
        <div className={expandedMenu ? styles['navbar-logo'] : styles['navbar-logo-small']}>
          <NavLink to="/dashboard">
            {expandedMenu ? (
              <img src={navbarLogo} alt="Tools for Brokers logo" />
            ) : (
              <img src={navbarLogoSmall} alt="Tools for Brokers logo" />
            )}
          </NavLink>
          {expandedMenu ? (
            <img src={collapseIcon} alt="Collapse" onClick={() => setExpandedMenu((state) => !state)} />
          ) : (
            <img src={expandIcon} alt="Collapse" onClick={() => setExpandedMenu((state) => !state)} />
          )}
        </div>

        {expandedMenu ? menuLarge : menuSmall}
      </div>
      <InvestorEditModal
        investor={linkedAccounts[0] ? linkedAccounts[0] : investor}
        show={showEditInvestorModal}
        toggleVisibility={toggleModalVisibility}
      />
      <MasterEditModal master={master} show={showEditMasterModal} toggleVisibility={toggleModalVisibility} />
    </>
  );
};

export default withRouter(GenUserNavbar);
