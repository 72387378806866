import * as React from 'react';
import { useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { useDispatch } from 'react-redux';
import { getInfoMastersThunkAC } from '../../redux/actions/master/fetchMasters';
import LocalStorageUtils from '../../utils/local-storage';

function saveLocation() {
  if (window.location.pathname !== '/login') {
    const accountType = LocalStorageUtils.getValueFromLocalStorage('accountType');
    LocalStorageUtils.putValueToLocalStorage(
      'rememberPage',
      JSON.stringify({ page: window.location.pathname, accountType: accountType || '' })
    );
  }
}

const routeComponent = (component: any, rest: any) => {
  if (rest.isAuthenticated) return component;

  saveLocation();

  return <Redirect to={{ pathname: '/login' }} />;
};

export const PrivateRoute = ({ component, ...rest }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInfoMastersThunkAC());
  }, []);

  return <Route {...rest} render={() => routeComponent(component, rest)} />;
};

export const PrivateRouteNoFetch = ({ component, ...rest }: any) => {
  return <Route {...rest} render={() => routeComponent(component, rest)} />;
};
