import React from 'react';

const AccountSettingsIcon: React.FC = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8926 13.9138H10.1519C10.2934 13.9137 10.4305 13.8652 10.5407 13.7765C10.6509 13.6878 10.7274 13.5641 10.7577 13.4259L11.014 12.2597C11.2608 12.1517 11.4981 12.0238 11.7238 11.8774C11.9421 10.3412 13.1831 9.13431 14.7354 8.96789L13.958 8.25869C13.9982 7.89317 13.9982 7.52434 13.958 7.15883L14.8425 6.35193C15.0566 6.15517 15.1063 5.83552 14.9623 5.5829L13.8326 3.62586C13.7618 3.50329 13.6513 3.40859 13.5193 3.35745C13.3874 3.30631 13.2419 3.30181 13.107 3.34469L11.9662 3.70779C11.6695 3.49035 11.35 3.30575 11.0134 3.15724L10.7577 1.98848C10.7276 1.85019 10.651 1.72636 10.5409 1.63751C10.4307 1.54867 10.2934 1.50015 10.1519 1.5H7.8926C7.75106 1.50015 7.61382 1.54867 7.50364 1.63751C7.39346 1.72636 7.31695 1.85019 7.28681 1.98848L7.03356 3.15662C6.86846 3.228 6.70708 3.30807 6.55005 3.39621C6.38743 3.49055 6.22977 3.59483 6.07832 3.70655L4.93812 3.34345C4.80316 3.30041 4.65753 3.30483 4.52543 3.35598C4.39333 3.40713 4.2827 3.50192 4.21191 3.62462L3.08225 5.58166C3.01205 5.70453 2.98566 5.84762 3.00743 5.98745C3.02919 6.12729 3.09782 6.25558 3.20205 6.35131L4.08653 7.15634C4.04631 7.52186 4.04631 7.89069 4.08653 8.25621L3.20205 9.0631C3.09796 9.15879 3.02944 9.28697 3.00767 9.42667C2.98591 9.56637 3.01221 9.70932 3.08225 9.83214L4.21439 11.7879C4.28518 11.9106 4.39581 12.0054 4.52792 12.0566C4.66002 12.1077 4.80564 12.1121 4.9406 12.0691L6.08081 11.706C6.37702 11.9242 6.69657 12.1089 7.03356 12.2566L7.28619 13.4259C7.31648 13.5642 7.39313 13.688 7.50343 13.7767C7.61372 13.8655 7.75104 13.9138 7.8926 13.9138ZM7.2642 5.95132C7.7298 5.48571 8.3613 5.22414 9.01977 5.22414C9.67824 5.22414 10.3097 5.48571 10.7753 5.95132C11.241 6.41693 11.5025 7.04843 11.5025 7.7069C11.5025 8.36537 11.241 8.99686 10.7753 9.46247C10.3097 9.92808 9.67824 10.1897 9.01977 10.1897C8.3613 10.1897 7.7298 9.92808 7.2642 9.46247C6.79859 8.99686 6.53701 8.36537 6.53701 7.7069C6.53701 7.04843 6.79859 6.41693 7.2642 5.95132Z"
        fill="currentColor"
      />
      <path
        d="M18.2023 13.2816C18.2023 14.9893 16.8179 16.3737 15.1102 16.3737C13.4024 16.3737 12.0181 14.9893 12.0181 13.2816C12.0181 11.5738 13.4024 10.1895 15.1102 10.1895C16.8179 10.1895 18.2023 11.5738 18.2023 13.2816Z"
        fill="currentColor"
      />
      <path
        d="M21.0001 22.5579H9.20703C9.35376 19.4282 11.9376 16.9359 15.1036 16.9359C18.2696 16.9359 20.8534 19.4282 21.0001 22.5579Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AccountSettingsIcon;
