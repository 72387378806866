import { Investor } from '../types/investorTypes';

export const getEquityOverInvestments = (investor: Investor): number | string => {
  if (!investor?.Investments) {
    return 0;
  }
  const equityOverInvestments = Number(
    (((investor?.EquityNoCredit - investor?.Investments) / Math.abs(investor?.Investments)) * 100).toFixed(2)
  );

  if (equityOverInvestments > 0) {
    return `+${Number(
      (((investor?.EquityNoCredit - investor?.Investments) / Math.abs(investor?.Investments)) * 100).toFixed(2)
    )}`;
  }

  return Number((((investor?.EquityNoCredit - investor?.Investments) / Math.abs(investor?.Investments)) * 100).toFixed(2));
};

export const getEquityOverInvestmentsCredit = (investor: Investor): number | string => {
  if (!investor?.Investments) {
    return 0;
  }
  const equityOverInvestments = Number(
    (((investor?.Equity - investor?.Investments) / Math.abs(investor?.Investments)) * 100).toFixed(2)
  );

  if (equityOverInvestments > 0) {
    return `+${Number((((investor?.Equity - investor?.Investments) / Math.abs(investor?.Investments)) * 100).toFixed(2))}`;
  }

  return Number((((investor?.Equity - investor?.Investments) / Math.abs(investor?.Investments)) * 100).toFixed(2));
};
