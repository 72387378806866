import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { IOrdersReload } from '../../../types/maintenanceTypes';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_ORDERS_RELOAD = 'FETCH_ORDERS_RELOAD';

export type OrdersReloadAction = {
  type: string;
  ordersReload: IOrdersReload;
};

const fetchOrdersReload = (ordersReload: IOrdersReload): OrdersReloadAction => {
  return {
    type: FETCH_ORDERS_RELOAD,
    ordersReload
  };
};

const fetchOrdersReloadAsync = (api: string, request: IOrdersReload) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/maintenance/orders/reload`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((ordersReload: any) => {
        dispatch(fetchOrdersReload(ordersReload));
        toast.success('Load Orders successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchOrdersReloadAsync;
