import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { MasterSettings } from '../../../types/settingsTypes';
import setLoadingStatus from '../../actions/setLoadingStatus';
import { fetchMasterDetails, fetchMasterDetailsDefault } from '../../actions/master/fetchMasterDetails';

export const FETCH_MASTER_SETTINGS = 'FETCH_MASTER_SETTINGS';

export type FetchMasterSettingsAction = {
  type: string;
  settings: MasterSettings;
};

const fetchMasterSettings = (settings: MasterSettings): FetchMasterSettingsAction => {
  return {
    type: FETCH_MASTER_SETTINGS,
    settings
  };
};

const fetchMasterSettingsAsync = (api: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/settings/account/master`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((settings: any) => dispatch(fetchMasterSettings(settings)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export const editMasterSettingsAsync = (api: string, request: any) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/settings/account/master`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((settings: any) => {
        dispatch(fetchMasterSettings(settings));
        toast.success('Masters settings edited successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

export const editMasterSettingsIdAsync = (api: string, id: number, request: any) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/masters/${id}`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((master: any) => {
        dispatch(fetchMasterDetails(master));
        toast.success(`Master ID ${id} settings edited successfully!`, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        fetch(`${api}/masters/${id}?returnAccountOptionsValues=true`, headersBuilder(Method.GET))
          .then((response: Response) => Errors.checkResponseForErrors(response, api))
          .then((masterDefault: any) => {
            dispatch(fetchMasterDetailsDefault(masterDefault));
          })
          .catch((error: Error) => Errors.throwErrorMessage(error))
          .finally(() => dispatch(setLoadingStatus(false)));
      })
      .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

export default fetchMasterSettingsAsync;
