import logo from '../logos/logo-light.png';

export const APP_LOGO = {
  src: logo,
  // height: 48,
  // width: 215,
  className: 'mr-3',
  alt: 'PAMM',
  href: '/'
};
