import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { CalcMethod, GraphicsPeriodTypes, Summary } from '../../../types/sharedTypes';
import { getFromDateForRequest, getToDateForRequest } from '../../../utils/date-time';
import { AccountType } from '../../../types/authTypes';

export const FETCH_PROFIT = 'FETCH_PROFIT';
export const FETCH_PROFIT_MASTER = 'FETCH_PROFIT_MASTER';
export const FETCH_PROFIT_OWNFUNDS = 'FETCH_PROFIT_OWNFUNDS';
export const FETCH_PROFIT_PER_PERIOD = 'FETCH_PROFIT_PER_PERIOD';
export const FETCH_ARRAY_OF_PROFITS = 'FETCH_ARRAY_OF_PROFITS';
export const FETCH_PROFIT_OVERALL = 'FETCH_PROFIT_OVERALL';
export const FETCH_ARRAY_OF_PROFITS_ALL_TIME = 'FETCH_ARRAY_OF_PROFITS_ALL_TIME';

export type FetchProfitAction = {
  type: string;
  profitFortnight: Array<Summary>;
};

export type FetchArrayOfProfitsAction = {
  type: string;
  masterId: number;
  profitFortnight: Array<Summary>;
};

export type FetchArrayOfProfitsAllTimeAction = {
  type: string;
  userId: number;
  profitFortnightAllTime: Array<Summary>;
};

const fetchProfit = (profitFortnight: Array<Summary>): FetchProfitAction => {
  return {
    type: FETCH_PROFIT,
    profitFortnight
  };
};

const fetchProfitMaster = (profitFortnight: Array<Summary>): FetchProfitAction => {
  return {
    type: FETCH_PROFIT_MASTER,
    profitFortnight
  };
};

const fetchProfitOwnFunds = (profitFortnight: Array<Summary>): FetchProfitAction => {
  return {
    type: FETCH_PROFIT_OWNFUNDS,
    profitFortnight
  };
};

const fetchProfitPerPeriod = (profitFortnight: Array<Summary>): FetchProfitAction => {
  return {
    type: FETCH_PROFIT_PER_PERIOD,
    profitFortnight
  };
};

const fetchProfitOverall = (profitFortnight: Array<Summary>): FetchProfitAction => {
  return {
    type: FETCH_PROFIT_OVERALL,
    profitFortnight
  };
};

const fetchArrayOfProfits = (masterId: number, profitFortnight: Array<Summary>): FetchArrayOfProfitsAction => {
  return {
    type: FETCH_ARRAY_OF_PROFITS,
    masterId,
    profitFortnight
  };
};

const fetchArrayOfProfitsAllTime = (userId: number, profitFortnightAllTime: Array<Summary>): FetchArrayOfProfitsAllTimeAction => {
  return {
    type: FETCH_ARRAY_OF_PROFITS_ALL_TIME,
    userId,
    profitFortnightAllTime
  };
};

const fetchProfitAsync = (
  api: string,
  id: number,
  period: GraphicsPeriodTypes,
  dateFrom: Date,
  dateTo: Date,
  calcMethod?: string,
  isArray?: boolean,
  isAuth?: boolean,
  modeProfit?: string,
  accountType?: string
) => {
  let query = `${id}?period=${period}`;

  const dateTimeDateFrom = getFromDateForRequest(dateFrom);
  const dateTimeDateTo = getToDateForRequest(dateTo);

  if (dateFrom) query += `&from=${dateTimeDateFrom}`;
  if (dateTo) query += `&to=${dateTimeDateTo}`;
  if (calcMethod) query += `&calcMethod=${calcMethod}`;
  if (modeProfit) query += `&mode=${modeProfit}`;

  return (dispatch: Dispatch): void => {
    // TODO fetch
    fetch(
      `${api}/summary/profit/${query}`,
      !isAuth
        ? headersBuilder(Method.GET)
        : {
            method: 'GET',
            body: undefined,
            headers: {
              'Content-Type': 'application/json'
            }
          }
    )
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((data: any) => {
        if (calcMethod === 'running') {
          dispatch(fetchProfitPerPeriod(data));
        } else if (calcMethod === 'RunningWithPreviousValue') {
          dispatch(fetchProfitOverall(data));
        }

        if (accountType === AccountType.Investor && !isArray) {
          dispatch(fetchProfit(data));
        }

        if (accountType === AccountType.Master) {
          dispatch(fetchProfitMaster(data));
        }

        if (accountType === AccountType.OwnFunds) {
          dispatch(fetchProfitOwnFunds(data));
        }

        if (isArray) {
          if (calcMethod === CalcMethod.Regular) {
            dispatch(fetchArrayOfProfitsAllTime(id, data));
          } else if (calcMethod !== CalcMethod.Regular) dispatch(fetchArrayOfProfits(id, data));
        }
      })
      .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

export default fetchProfitAsync;
