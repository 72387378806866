import { History } from 'history';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import LocalStorageUtils from '../../../utils/local-storage';
import { getJwt } from '../auth';
import fetchRequestsCountAsync from '../requests/fetchRequestsCount';

export type InvestorAttachedAction = {
  type: string;
  masterId: number;
};

const attachInvestor = (api: string, investorId: string, masterId: string, history: History, isMobile?: boolean) => {
  return (): void => {
    fetch(`${api}/investors/attach/${investorId}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ MasterId: parseInt(masterId, 10) })
    })
      .then((response) => Errors.checkResponseForErrors(response, api))
      // eslint-disable-next-line no-alert
      .then(() => {
        LocalStorageUtils.removeValueFromLocalStorage('investorToAttach');
        toast.success('Investor attached successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        history.push(`/investors/${investorId}/overall-profit/open-orders`);

        // TODO PAMM-2705
        // if (Array.isArray(history)) {
        //   history.push(`/investors/${investorId}/overall-profit/open-orders`);
        // } else {
        //   window.location.reload()
        // }
      })
      .catch((error) => Errors.throwErrorMessage(error));
  };
};

export const createRequestToAttachInvestor = (
  api: string,
  investorId: string,
  masterId: string,
  history: History,
  isMobile?: boolean
) => {
  return (dispatch: any): void => {
    fetch(`${api}/requests/create/attachInvestor`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ MmId: parseInt(masterId, 10), AccountId: parseInt(investorId, 10) })
    })
      .then((response) => Errors.checkResponseForErrors(response, api))
      // eslint-disable-next-line no-alert
      .then(() => {
        LocalStorageUtils.putValueToLocalStorage('masterIdAttachRequestSent', masterId);
        toast.success('Request created successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch(fetchRequestsCountAsync(api));
        history.push('/dashboard');
      })
      .catch((error) => Errors.throwErrorMessage(error));
  };
};

export default attachInvestor;
