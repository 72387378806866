import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { BalanceOperation, PaginationInfo } from '../../../types/sharedTypes';
import { setLoadingStatusBalanceOperations } from '../setLoadingStatus';

export const FETCH_BALANCE_OPERATIONS = 'FETCH_BALANCE_OPERATIONS';

export type FetchBalanceOperationsAction = {
  type: string;
  balanceOperations: any;
  paginationInfo: PaginationInfo;
};

const fetchBalanceOperations = (
  balanceOperations: Array<BalanceOperation>,
  paginationInfo: PaginationInfo
): FetchBalanceOperationsAction => {
  return {
    type: FETCH_BALANCE_OPERATIONS,
    balanceOperations,
    paginationInfo
  };
};

const getTypeFilters = (filtersStateObject: Record<string, boolean>): string => {
  let filters = '';

  Object.entries(filtersStateObject).forEach((entry) => {
    if (entry[1]) {
      filters += `&typeFilter=${entry[0]}`;
    }
  });

  return filters;
};

const fetchBalanceOperationsAsync = (
  api: string,
  userType: string,
  id: string,
  typeFilters: Record<string, boolean>,
  page?: number,
  limit?: number,
  sortField?: string,
  direction?: string
) => {
  let queryString = `?page=${page}`;

  if (limit) queryString += `&limit=${limit}`;
  if (sortField) queryString += `&sortField=${sortField}`;
  if (direction) queryString += `&direction=${direction}`;

  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatusBalanceOperations(true));

    fetch(
      `${api}/${
        userType === 'investor' ? 'investors' : 'masters'
      }/balance/operations/${id}${queryString}&from=1970-01-01${getTypeFilters(typeFilters)}`,
      headersBuilder(Method.GET)
    )
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((data: any) => dispatch(fetchBalanceOperations(data.Data, data.PaginationInfo)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatusBalanceOperations(false)));
  };
};

export default fetchBalanceOperationsAsync;
