import { Dispatch } from 'redux';
import headersBuilder, { Method } from '../../../utils/request';
import { Admin } from '../../../types/adminTypes';
import setLoadingStatus from '../setLoadingStatus';
import Errors from '../../../shared/validation/errors';

export const FETCH_ADMIN_DETAILS = 'FETCH_ADMIN_DETAILS ';

export type FetchAdminDetailsAction = {
  type: string;
  adminDetails: Admin;
};

export const fetchAdminDetails = (admin: Admin): FetchAdminDetailsAction => {
  return {
    type: FETCH_ADMIN_DETAILS,
    adminDetails: admin
  };
};

function fetchAdminDetailsAsync(api: string, adminId: number) {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/admins/${adminId}`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((admin: any) => dispatch(fetchAdminDetails(admin)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
}

export default fetchAdminDetailsAsync;
