import { FETCH_REQUESTS, FetchRequestsAction } from '../../actions/requests/fetchRequests';
import { FETCH_REQUESTS_COUNT, FetchRequestsCountAction } from '../../actions/requests/fetchRequestsCount';
import { FORCE_UPDATE, ForceUpdateAction } from '../../actions/requests/forceUpdate';

import { PaginationInfo } from '../../../types/sharedTypes';

export interface RequestsState {
  requests: Array<any>;
  paginationInfo: PaginationInfo;
  count: number;
  forceUpdate: number;
}
// Components listen to force update to trigger a refetch of requests (since we currently have no easy way of updating the requests list by waiting for the post request to end)
const initialState: RequestsState = {
  requests: [],
  paginationInfo: new PaginationInfo(),
  count: 0,
  forceUpdate: Math.random()
};

type RequestsAction = FetchRequestsAction & FetchRequestsCountAction & ForceUpdateAction;

const requestsReducer = (state: RequestsState = initialState, action: RequestsAction): RequestsState => {
  switch (action.type) {
    case FETCH_REQUESTS:
      return {
        ...state,
        requests: action.requests,
        paginationInfo: action.paginationInfo
      };
    case FETCH_REQUESTS_COUNT:
      return {
        ...state,
        count: action.count
      };
    case FORCE_UPDATE:
      return {
        ...state,
        forceUpdate: Math.random()
      };
    default:
      return state;
  }
};

export default requestsReducer;
