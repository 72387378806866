const getCurrencySymbol = (currency: any, currenciesConfig?: Record<string, string>): string => {
  let result = '';
  if (currenciesConfig) {
    const currenciesConfigArray = Object.keys(currenciesConfig);

    if (currenciesConfigArray.includes(currency)) {
      result = currenciesConfig[currency];
    } else result = currency;
  }
  return result;
};

export default getCurrencySymbol;
