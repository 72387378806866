import React from 'react';

const WalletNavBarIcon: React.FC<{ size: number }> = ({ size }): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        d="M11.3333 8C11.1565 8 10.987 8.07024 10.8619 8.19526C10.7369 8.32029 10.6667 8.48986 10.6667 8.66667C10.6667 8.84348 10.7369 9.01305 10.8619 9.13807C10.987 9.2631 11.1565 9.33333 11.3333 9.33333H12.6667C12.8435 9.33333 13.013 9.2631 13.1381 9.13807C13.2631 9.01305 13.3333 8.84348 13.3333 8.66667C13.3333 8.48986 13.2631 8.32029 13.1381 8.19526C13.013 8.07024 12.8435 8 12.6667 8H11.3333ZM2 0C1.46957 0 0.960859 0.210714 0.585787 0.585787C0.210714 0.960859 0 1.46957 0 2V12.6667C0 13.5507 0.351189 14.3986 0.976311 15.0237C1.60143 15.6488 2.44928 16 3.33333 16H13.3333C14.0406 16 14.7189 15.719 15.219 15.219C15.719 14.7189 16 14.0406 16 13.3333V5.33333C16 4.86525 15.8768 4.40541 15.6427 4.00004C15.4087 3.59467 15.072 3.25804 14.6667 3.024V2.66667C14.6667 1.95942 14.3857 1.28115 13.8856 0.781048C13.3855 0.280951 12.7072 0 12 0H2ZM2 1.33333H12C12.3536 1.33333 12.6928 1.47381 12.9428 1.72386C13.1929 1.97391 13.3333 2.31304 13.3333 2.66667H2C1.82319 2.66667 1.65362 2.59643 1.5286 2.4714C1.40357 2.34638 1.33333 2.17681 1.33333 2C1.33333 1.82319 1.40357 1.65362 1.5286 1.5286C1.65362 1.40357 1.82319 1.33333 2 1.33333ZM2 4H13.3333C13.687 4 14.0261 4.14048 14.2761 4.39052C14.5262 4.64057 14.6667 4.97971 14.6667 5.33333V13.3333C14.6667 13.687 14.5262 14.0261 14.2761 14.2761C14.0261 14.5262 13.687 14.6667 13.3333 14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V3.88667C1.54133 3.96 1.76667 4 2 4Z"
        fill="#24305E"
      />
    </svg>
  );
};

export default WalletNavBarIcon;
