import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import { IPerformanceFeeCharge } from '../../../types/maintenanceTypes';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_PERFORMANCE_FEE_CHARGE = 'FETCH_PERFORMANCE_FEE_CHARGE';

export type PerformanceFeeChargeAction = {
  type: string;
  performanceFeeCharge: IPerformanceFeeCharge;
};

const fetchPerformanceFeeCharge = (performanceFeeCharge: IPerformanceFeeCharge): PerformanceFeeChargeAction => {
  return {
    type: FETCH_PERFORMANCE_FEE_CHARGE,
    performanceFeeCharge
  };
};

const fetchPerformanceFeeChargeAsync = (api: string, account: string, request: IPerformanceFeeCharge) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/${account}/calcIncentive`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((performanceFeeCharge: any) => {
        dispatch(fetchPerformanceFeeCharge(performanceFeeCharge));
        toast.success('Performance fee charge successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchPerformanceFeeChargeAsync;
