/* eslint-disable no-restricted-syntax */
function applyStyles(styles: string[] | string) {
  let returnStyles = '';
  if (Array.isArray(styles)) {
    for (const i of styles) {
      returnStyles += `${i} `;
    }
    return returnStyles;
  }
  return styles;
}

export { applyStyles };
