import React from 'react';

const DashboardIcon: React.FC = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path d="M3 1.5H1V23.5H23V21.5H3V1.5Z" fill="currentColor" />
      <path
        d="M8 6C5.6 5.6 4.33333 15.1667 4 20V20.5H21V2.50005C17 1.00005 16 15.0001 14.5 16.5C13.7 17.2999 13 16 12.5 14C11.5 9.99996 10.4 6.4 8 6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DashboardIcon;
