import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { ISendReport } from '../../../types/maintenanceTypes';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_SEND_REPORT = 'FETCH_SEND_REPORT';

export type SendReportAction = {
  type: string;
  sendReport: ISendReport;
};

const fetchSendReport = (sendReport: ISendReport): SendReportAction => {
  return {
    type: FETCH_SEND_REPORT,
    sendReport
  };
};

const fetchSendReportAsync = (api: string, request: ISendReport) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/maintenance/sendReport`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((sendReport: any) => {
        dispatch(fetchSendReport(sendReport));
        toast.success('Send report successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchSendReportAsync;
