export const SET_LOADING_STATUS = 'SET_LOADING_STATUS ';
export const CHECK_MT_SERVERS_UPDATE = 'CHECK_MT_SERVERS_UPDATE';
export const SET_LOADING_STATUS_DOWNLOAD = 'SET_LOADING_STATUS_DOWNLOAD';
export const SET_LOADING_STATUS_OPEN_ORDERS = 'SET_LOADING_STATUS_OPEN_ORDERS';
export const SET_LOADING_STATUS_CLOSED_ORDERS = 'SET_LOADING_STATUS_CLOSED_ORDERS';
export const SET_LOADING_STATUS_BALANCE_OPERATIONS = 'SET_LOADING_STATUS_BALANCE_OPERATIONS';
export const SET_LOADING_STATUS_CONNECTED_INVESTORS = 'SET_LOADING_STATUS_CONNECTED_INVESTORS';

export type SetLoadingStatusAction = {
  type: string;
  status: boolean;
};

export const setLoadingStatus = (status: boolean): SetLoadingStatusAction => {
  return {
    type: SET_LOADING_STATUS,
    status
  };
};

export const setLoadingStatusDownload = (status: boolean): SetLoadingStatusAction => {
  return {
    type: SET_LOADING_STATUS_DOWNLOAD,
    status
  };
};

export const checkMtServersUpdate = (status: boolean): SetLoadingStatusAction => {
  return {
    type: CHECK_MT_SERVERS_UPDATE,
    status
  };
};

export const setLoadingStatusOpenOrders = (status: boolean): SetLoadingStatusAction => {
  return {
    type: SET_LOADING_STATUS_OPEN_ORDERS,
    status
  };
};

export const setLoadingStatusClosedOrders = (status: boolean): SetLoadingStatusAction => {
  return {
    type: SET_LOADING_STATUS_CLOSED_ORDERS,
    status
  };
};

export const setLoadingStatusBalanceOperations = (status: boolean): SetLoadingStatusAction => {
  return {
    type: SET_LOADING_STATUS_BALANCE_OPERATIONS,
    status
  };
};

export const setLoadingStatusConnectedInvestors = (status: boolean): SetLoadingStatusAction => {
  return {
    type: SET_LOADING_STATUS_CONNECTED_INVESTORS,
    status
  };
};
export default setLoadingStatus;
