import * as Yup from 'yup';
import { IntlShape } from 'react-intl';

export class CommonSchemas {
  public static readonly VALIDATOR_REQUIRED_KEY = 'validators.error.cannotBeEmpty';

  private readonly intl: IntlShape;

  constructor(intl: IntlShape) {
    this.intl = intl;
  }

  public get stringRequired(): Yup.StringSchema<string | null> {
    return Yup.string().required(this.intl.formatMessage({ id: CommonSchemas.VALIDATOR_REQUIRED_KEY }));
  }

  public get positiveNumberOrZero(): Yup.NumberSchema<number | null> {
    return Yup.number()
      .moreThan(-1, this.intl.formatMessage({ id: 'validators.error.mustBePositive' }))
      .typeError(this.intl.formatMessage({ id: 'validators.error.mustBeNumber' }))
      .nullable()
      .transform((v, o) => (o === '' ? null : v));
  }

  public get positiveNumberOrZeroRequired(): Yup.NumberSchema<number | null> {
    return Yup.number()
      .required(this.intl.formatMessage({ id: CommonSchemas.VALIDATOR_REQUIRED_KEY }))
      .moreThan(-1, this.intl.formatMessage({ id: 'validators.error.mustBePositive' }))
      .typeError(this.intl.formatMessage({ id: 'validators.error.mustBeNumber' }))
      .nullable()
      .transform((v, o) => (o === '' ? null : v));
  }

  public get positiveNumberRequired(): Yup.NumberSchema<number | null> {
    return Yup.number()
      .required(this.intl.formatMessage({ id: CommonSchemas.VALIDATOR_REQUIRED_KEY }))
      .moreThan(0, this.intl.formatMessage({ id: 'validators.error.mustBePositiveNoZero' }))
      .typeError(this.intl.formatMessage({ id: 'validators.error.mustBePositiveNoZero' }))
      .nullable()
      .transform((v, o) => (o === '' ? null : v));
  }
}
