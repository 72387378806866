import {
  FETCH_MASTER_DETAILS,
  FetchDetailsAction,
  FetchDetailsDefaultAction,
  FETCH_MASTER_DETAILS_DEFAULT,
  RESET_MASTER_DETAILS
} from '../../actions/master/fetchMasterDetails';
import { FETCH_MASTER_PAYMENT_PERIODS, FetchMasterPaymentPeriodsAction } from '../../actions/master/fetchMasterPaymentPeriods';
import { IPaymentPeriod, Master } from '../../../types/masterTypes';

export interface MasterDetailsState {
  master: Master;
  masterDefault: Master;
  paymentPeriods: Array<IPaymentPeriod>;
}

const initialState: MasterDetailsState = {
  master: new Master(),
  masterDefault: new Master(),
  paymentPeriods: []
};

type FetchMasterDetailsAction = FetchDetailsAction & FetchMasterPaymentPeriodsAction & FetchDetailsDefaultAction;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const masterReducer = (state: MasterDetailsState = initialState, action: FetchMasterDetailsAction): MasterDetailsState => {
  switch (action.type) {
    case FETCH_MASTER_DETAILS:
      return {
        ...state,
        master: action.masterDetails
      };

    case FETCH_MASTER_PAYMENT_PERIODS:
      return {
        ...state,
        paymentPeriods: action.paymentPeriods
      };
    case FETCH_MASTER_DETAILS_DEFAULT:
      return {
        ...state,
        masterDefault: action.masterDetailsDefault
      };
    case RESET_MASTER_DETAILS:
      return {
        master: new Master(),
        masterDefault: new Master(),
        paymentPeriods: []
      };
    default:
      return state;
  }
};

export default masterReducer;
