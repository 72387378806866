import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { IOrdersCompare } from '../../../types/maintenanceTypes';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_ORDERS_COMPARE = 'FETCH_ORDERS_COMPARE';

export type OrdersCompareAction = {
  type: string;
  ordersCompare: IOrdersCompare;
};

const fetchOrdersCompare = (ordersCompare: IOrdersCompare): OrdersCompareAction => {
  return {
    type: FETCH_ORDERS_COMPARE,
    ordersCompare
  };
};

const fetchOrdersCompareAsync = (api: string, request: IOrdersCompare) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/maintenance/orders/compare`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((ordersCompare: any) => {
        dispatch(fetchOrdersCompare(ordersCompare));
        toast.success('Load Orders successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchOrdersCompareAsync;
