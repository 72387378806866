import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import { FeeAccount } from '../../../types/feeAccountTypes';
import { PaginationInfo } from '../../../types/sharedTypes';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_FEE_ACCOUNTS = 'FETCH_FEE_ACCOUNTS';

export type FetchFeeAccountsAction = {
  type: string;
  feeAccounts: Array<FeeAccount>;
  paginationInfo: PaginationInfo;
};

const fetchFeeAccounts = (feeAccounts: Array<FeeAccount>, paginationInfo: PaginationInfo): FetchFeeAccountsAction => {
  return {
    type: FETCH_FEE_ACCOUNTS,
    feeAccounts,
    paginationInfo
  };
};

const fetchFeeAccountsAsync = (api: string, page?: number, limit?: number, direction?: string, query?: string) => {
  let queryString = `?page=${page}&limit=${limit}&direction=${direction}`;

  if (query) queryString += `&query=${query}`;

  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/admins/feeAccounts${queryString}`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((json: any) => dispatch(fetchFeeAccounts(json.Data, json.PaginationInfo)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchFeeAccountsAsync;
