import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { InvestorSettings } from '../../../types/settingsTypes';
import setLoadingStatus from '../../actions/setLoadingStatus';
import { fetchInvestorDetails, fetchInvestorDetailsFalse } from '../investor/fetchInvestorDetails';

export const FETCH_INVESTOR_SETTINGS = 'FETCH_INVESTOR_SETTINGS';

export type FetchInvestorSettingsAction = {
  type: string;
  settings: InvestorSettings;
};

const fetchInvestorSettings = (settings: InvestorSettings): FetchInvestorSettingsAction => {
  return {
    type: FETCH_INVESTOR_SETTINGS,
    settings
  };
};

const fetchInvestorSettingsAsync = (api: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/settings/account/investor`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((settings: any) => dispatch(fetchInvestorSettings(settings)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export const editInvestorSettingsAsync = (api: string, request: any) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/settings/account/investor`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((settings: any) => {
        dispatch(fetchInvestorSettings(settings));
        toast.success('Investors settings edited successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

export const editInvestorSettingsIdAsync = (api: string, id: number, request: any) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/investors/${id}`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((investor: any) => {
        dispatch(fetchInvestorDetails(investor));
        dispatch(fetchInvestorDetailsFalse(investor));
        toast.success(`Investor ID ${id} settings edited successfully!`, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

export default fetchInvestorSettingsAsync;
