import React from 'react';

const HomePageIcon: React.FC = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
      <path
        d="M21.5447 10.3298L11.5489 0.341257C11.4769 0.269144 11.3914 0.211933 11.2973 0.172897C11.2032 0.133862 11.1023 0.11377 11.0005 0.11377C10.8986 0.11377 10.7977 0.133862 10.7036 0.172897C10.6095 0.211933 10.524 0.269144 10.452 0.341257L0.456229 10.3298C0.165019 10.621 0 11.0165 0 11.4291C0 12.2857 0.696477 12.9822 1.55312 12.9822H2.60633V20.1096C2.60633 20.5391 2.95335 20.8861 3.38289 20.8861H9.44734V15.4502H12.1653V20.8861H18.618C19.0476 20.8861 19.3946 20.5391 19.3946 20.1096V12.9822H20.4478C20.8603 12.9822 21.2559 12.8196 21.5471 12.526C22.1514 11.9193 22.1514 10.9364 21.5447 10.3298Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HomePageIcon;
