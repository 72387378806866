import {
  SET_LOADING_STATUS,
  SetLoadingStatusAction,
  CHECK_MT_SERVERS_UPDATE,
  SET_LOADING_STATUS_DOWNLOAD,
  SET_LOADING_STATUS_CONNECTED_INVESTORS,
  SET_LOADING_STATUS_BALANCE_OPERATIONS,
  SET_LOADING_STATUS_CLOSED_ORDERS,
  SET_LOADING_STATUS_OPEN_ORDERS
} from '../actions/setLoadingStatus';

export interface LoadingStatusState {
  isLoading: boolean;
  isLoadingDownload: boolean;
  checkMtServers: boolean;
  isLoadingOpenOrders: boolean;
  isLoadingClosedOrders: boolean;
  isLoadingBalanceOperations: boolean;
  isLoadingConnectedInvestors: boolean;
}

const initialState: LoadingStatusState = {
  isLoading: false,
  isLoadingDownload: false,
  checkMtServers: false,
  isLoadingOpenOrders: false,
  isLoadingClosedOrders: false,
  isLoadingBalanceOperations: false,
  isLoadingConnectedInvestors: false
};

function loadingStatusReducer(state: LoadingStatusState = initialState, action: SetLoadingStatusAction): LoadingStatusState {
  switch (action.type) {
    case SET_LOADING_STATUS: {
      return {
        ...state,
        isLoading: action.status
      };
    }

    case SET_LOADING_STATUS_DOWNLOAD: {
      return {
        ...state,
        isLoadingDownload: action.status
      };
    }
    case CHECK_MT_SERVERS_UPDATE: {
      return {
        ...state,
        checkMtServers: action.status
      };
    }
    case SET_LOADING_STATUS_OPEN_ORDERS: {
      return {
        ...state,
        isLoadingOpenOrders: action.status
      };
    }
    case SET_LOADING_STATUS_CLOSED_ORDERS: {
      return {
        ...state,
        isLoadingClosedOrders: action.status
      };
    }
    case SET_LOADING_STATUS_BALANCE_OPERATIONS: {
      return {
        ...state,
        isLoadingBalanceOperations: action.status
      };
    }
    case SET_LOADING_STATUS_CONNECTED_INVESTORS: {
      return {
        ...state,
        isLoadingConnectedInvestors: action.status
      };
    }
    default: {
      return state;
    }
  }
}

export default loadingStatusReducer;
