import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { IBalanceOperation } from '../../../types/maintenanceTypes';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_EDIT_BALANCE_OPERATION = 'FETCH_EDIT_BALANCE_OPERATION';

export type EditBalanceOperationAction = {
  type: string;
  balanceOperation: IBalanceOperation;
};

const fetchEditBalanceOperation = (balanceOperation: IBalanceOperation): EditBalanceOperationAction => {
  return {
    type: FETCH_EDIT_BALANCE_OPERATION,
    balanceOperation
  };
};

const fetchEditBalanceOperationAsync = (api: string, request: IBalanceOperation, operationId: any) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/maintenance/edit/balanceOperation/${operationId}`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((balanceOperation: any) => {
        dispatch(fetchEditBalanceOperation(balanceOperation));
        toast.success('Load Orders successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchEditBalanceOperationAsync;
