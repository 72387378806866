import React from 'react';

const MoneyManagersIcon: React.FC = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.5C1.34315 2.5 0 3.84315 0 5.5V16.5C0 18.1569 1.34315 19.5 3 19.5H3.17071C3.06015 19.1872 3 18.8506 3 18.5V8.5C3 6.84315 4.34315 5.5 6 5.5H19C19.3506 5.5 19.6872 5.56015 20 5.67071V5.5C20 3.84315 18.6569 2.5 17 2.5H3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.5C6.34315 7.5 5 8.84315 5 10.5V20.5C5 22.1569 6.34315 23.5 8 23.5H21C22.6569 23.5 24 22.1569 24 20.5V10.5C24 8.84315 22.6569 7.5 21 7.5H8ZM20 18.5C21.1046 18.5 22 17.6046 22 16.5C22 15.3954 21.1046 14.5 20 14.5C18.8954 14.5 18 15.3954 18 16.5C18 17.6046 18.8954 18.5 20 18.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MoneyManagersIcon;
