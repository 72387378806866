/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { PAMMState } from '../../../redux/reducers/rootReducer';
import styles from './PasswordTooltip.module.css';

type Position = 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
interface PasswordTooltipProps {
  userType: string;
  accountType?: string;
  top?: string;
  position?: Position;
}

const PasswordTooltip: React.FC<PasswordTooltipProps> = ({ userType, accountType, top, position }): JSX.Element => {
  const customColors = useSelector((state: PAMMState) => state.configReducer.customColorsGeneralUser);

  const isAdmin = userType === 'Admin' || userType === 'admin';
  const color = isAdmin ? '#2C405A' : customColors.active;
  const userTypeBoolean =
    userType === 'master' || userType === 'investor' || accountType === 'investor' || accountType === 'master';

  const requirements = ['between', 'lettersNumbersCharacters', 'upperLowerCase', 'digit'];

  const renderRequirement = (req: string, index: number) => (
    <div key={`key-${index}`} className={styles.requirementItem}>
      <div className={styles['ellipse']} style={{ background: userTypeBoolean ? color : '#2C405A' }} />
      <div className={styles['requirementText']}>
        <FormattedMessage id={`passwordTooltip.${req}`} />
      </div>
    </div>
  );

  return (
    <div className={userTypeBoolean ? styles['masterRegistration'] : styles['adminRegistration']} style={{ top, position }}>
      <div className={styles['frame']}>
        <div className={styles['passwordRequirements']}>
          <FormattedMessage id="passwordTooltip.title" />:
        </div>
        <div className={styles['requirementsList']}>
          {requirements.map(renderRequirement)}
          <div className={styles['specialCharacterRequirement']}>
            <div className={styles['ellipse']} style={{ background: userTypeBoolean ? color : '#2C405A' }} />
            <div className={styles['requirementSpecialCharacter']}>
              <span>
                <FormattedMessage id="passwordTooltip.specialCharacter" />:{' '}
              </span>
              <span style={{ color: userTypeBoolean ? color : '#2C405A' }}>
                ! @ # $ | * % <br /> &amp; ` ~ ^ * ( ) - _ = + {} [ ] ; : ' " \ | / ? &lt; &gt; . ,
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordTooltip;
