import { StateToggle, VisibilityToggle } from './toggles';

export class AccountOptionsAdmin {
  EmailNotifications: StateToggle = StateToggle.Off;
  OrdersCommissions: VisibilityToggle = VisibilityToggle.Hide;
}

export class Admin {
  Id = 0;
  Name = '';
  Username = '';
  Email = '';
  ViewOnly = 'false';
  AccountOptions = new AccountOptionsAdmin();
  Mode = '';
  ModeCode = 0;
}

export class CreateAdmin {
  ViewOnly = false;
  Name = '';
  Username = '';
  Password = '';
  Email = '';
  // AccountOptions = new AccountOptionsAdmin();
}

export class EditAdminRequest {
  Name = '';
  Username = '';
  Email = '';
  AccountOptions = new AccountOptionsAdmin();
  Password = '';
}

export class EditAdminForm {
  Name = '';
  Username = '';
  Email = '';
  AccountOptions = new AccountOptionsAdmin();
  Password = '';
  ViewOnly = 'false';
}

export enum IAdminMode {
  Normal = 0,
  Disabled = 1
}

export enum GraphicsPeriodTypes {
  Day = 'Day',
  Week = 'Week',
  Fortnight = 'Fortnight',
  Month = 'Month',
  Year = 'Year'
}
